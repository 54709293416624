//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { documentBillAddDoc, documentBillDetail, documentBillEditDoc } from '@/api/modular/fileStream/documentManage'
import { selectPerson } from '@/mixins'
import { EventBus } from '@/views/fileStream/documentManage/bus.js'
import { customFromValidate } from '@/views/fileStream/documentManage/minxins.js'
import templateNodes from '@/views/fileStream/documentManage/templateNodes.vue'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import selectPersonAndDepart, {
    dealResult,
    getCorpId,
    getUserListMsg,
} from '@/views/fileStream/documentManage/weixin/selectPerson'
import selectPersonDialog from '@/views/fileStream/documentManage/weixin/selectPersonDialog'
import nodes from '@/views/fileStream/documentSend/components/nodes.vue'
import moment from 'moment'

export default {
    components: {
        OpenData,
        templateNodes,
        selectPersonDialog,
        nodes,
    },
    mixins: [selectPerson, customFromValidate],
    computed: {
        allList() {
            return [...this.mainList, ...this.csr_list]
        },
    },
    data() {
        return {
            moment,
            subLoad: false,
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
            form: this.$form.createForm(this),
            selectTemplateId: '',
            selectTemplate: null,
            csr_list: [],
            mainList: [],
            form_id: null,
            form_state: {},
            // 表單對象
            tableForm: null,
        }
    },
    props: {
        content: {
            type: String,
            default: '',
        },
        fileIds: {
            type: Array,
            default() {
                return []
            },
        },
        todoType: {
            type: String,
            default: 'registration',
        },
        officialId: {
            type: String,
            default: '',
        },
        randomCode: 0,
    },
    watch: {
        randomCode: {
            immediate: true,
            handler() {
                this.get_info()
            },
        },
        selectTemplateId(val) {},
    },
    created() {
        this.getFormStateData()
    },
    destroyed() {
        EventBus.$off('form_state', {})
    },
    methods: {
        getFormStateData() {
            EventBus.$on('form_state', (obj, form) => {
                this.form_state = obj
                this.tableForm = form
            })
        },
        getDataType({ deptId }) {
            return deptId ? 'departmentName' : 'userName'
        },
        formatDraftId(draftId, key) {
            if (!draftId) return
            return JSON.parse(draftId)[0][key]
        },
        resetMess() {
            this.mainList = []
            this.csr_list = []
            this.form_state = {}
            this.form.resetFields()
            this.selectTemplateId = ''
        },
        resetList() {
            this.resetMess()
        },
        // 刪除多余屬性
        delEmptKey(obj) {
            const objCopy = { ...obj }
            const keys = Object.keys(objCopy)
            for (const index of keys) {
                if (!objCopy[index]) delete objCopy[index]
            }
            return objCopy
        },
        get_info() {
            this.form_id = this.officialId
            const id = this.form_id
            if (id) {
                documentBillDetail({ id }).then((res) => {
                    const {
                        name,
                        urgencyDegree,
                        secrecyDegree,
                        deviseOpinion,
                        draftId,
                        // approvalWay,
                        tellPhone,
                        summary,
                        title,
                        documentNodeList,
                        documentFileList, //附件
                        documentNumber,
                        mainFile, //正文
                        checkIds,
                        draftCompany,
                        mainIds,
                        sendIds,
                        outDraftCompany,
                        outDraftDesc,
                        meetCompany,
                        meetDesc,
                        templateId,
                    } = res.data
                    const form = {
                        name,
                        meetDesc,
                        meetCompany,
                        outDraftCompany,
                        outDraftDesc,
                        jjcd: urgencyDegree ? String(urgencyDegree) : '',
                        miji: secrecyDegree ? String(secrecyDegree) : '',
                        nbyj: deviseOpinion,
                        draftId: this.formatDraftId(draftId),
                        checkIds: this.formatDraftId(checkIds),
                        draftCompany: this.formatDraftId(draftCompany),
                        wjbt: title,
                        wgbt: title,
                        nodes: documentNodeList,
                        tellPhone,
                        attach: mainFile && [mainFile], //正文
                        fileIds: Array.isArray(documentFileList) ? documentFileList : [], //附件
                        summary,
                        documentNumber,
                        templateId,
                    }
                    this.csr_list = this.formatDraftId(sendIds) // 抄送人
                    this.mainList = this.formatDraftId(mainIds) // 主送人
                    this.tableForm.setFieldsValue(form)
                    //
                    this.$emit('setDetail', form)
                })
            } else {
                this.resetMess()
            }
        },
        async handleSubmit(e, status) {
            const { filter, csr_list, mainList } = this
            if (!mainList?.length) {
                this.$message.error('審批流程未選擇，請選擇審批人員')
                return
            }
            if (this.subLoad) return
            let arr = await this.handlerNodeList()
            // let arr = []
            let func = documentBillAddDoc
            const id = this.form_id
            if (id) {
                func = documentBillEditDoc
            }
            e && e.preventDefault()
            const getCorpName = (ngdwListInput) => {
                if (!ngdwListInput) return ''
                return JSON.stringify([
                    {
                        type: 'departmentName',
                        corpId: getCorpId(),
                        id: '',
                        corpName: ngdwListInput,
                    },
                ])
            }
            this.tableForm.validateFields((err, values) => {
                this.setFromValidateState({ fromValidateState: true })
                if (!err) {
                    let params = {
                        draftCompany: this.form_state.draftCompany,
                        draftId: this.form_state.draftId,
                        checkIds: this.form_state.checkIds,
                        name: this.form_state.name,
                        ngdwListInput: this.form_state.ngdwListInput,
                        fileIds: this.form_state.fileIds,
                        attach: this.form_state.attach,
                    }
                    this.form_state = { ...values, ...params }
                    if (!this.form_state.name) return
                    if (params.draftCompany?.length < 1 && !params.ngdwListInput) return
                    if (params.draftId?.length < 1) return
                    if (params.checkIds?.length < 1) return
                    this.subLoad = true
                    const obj = {
                        name: this.form_state.name || '發文稿紙',
                        urgencyDegree: Number(this.form_state.jjcd) || '',
                        secrecyDegree: Number(this.form_state.miji) || '',
                        // fileType: Number(values.xzfn) || '',
                        documentNumber: this.form_state.documentNumber,
                        outDraftDesc: this.form_state.outDraftDesc,
                        outDraftCompany: this.form_state.outDraftCompany,
                        meetCompany: this.form_state.meetCompany,
                        meetDesc: this.form_state.meetDesc,
                        title: this.form_state?.wgbt,
                        documentType: 2,
                        nodeParam: {
                            templateId: this.selectTemplateId,
                            processesList: arr,
                        },
                        summary: this.form_state.summary,
                        deviseOpinion: this.form_state.nbyj,
                        draftId: filter(this.form_state.draftId),
                        checkIds: filter(this.form_state.checkIds),
                        draftCompany:
                            getCorpName(this.form_state.ngdwListInput) || filter(this.form_state.draftCompany),
                        sendIds: filter(csr_list), // 抄送人
                        mainIds: filter(mainList), // 主送人
                        // tellPhone: this.form_state?.tellPhone,
                        attach: this.form_state?.attach && this.form_state?.attach[0], // 正文
                        fileIds: this.form_state?.fileIds, // 附件
                        status,
                    }
                    //
                    if (id) {
                        obj.id = id
                    }
                    func(this.delEmptKey(obj))
                        .then((res) => {
                            if (!res.success) {
                                return
                            }
                            this.$message.success(res.message)
                            this.resetMess()
                            this.$emit('close')
                            //
                        })
                        .finally(() => {
                            this.subLoad = false
                        })
                } else {
                    this.$message.error('表單填寫未完成，請繼續填寫')
                }
            })
        },
        choosePerson(list, index) {
            let val
            if (list === 'list') {
                val = [...this[list][index].val]
            } else {
                val = [...this[list]]
            }
            const {
                selectedOpenUserIds,
                selectedCorpGroupUserIds,
                selectedDepartmentIds,
                selectedCorpGroupDepartmentIds,
                isList,
            } = getUserListMsg(list, index, val)
            selectPersonAndDepart({
                selectedOpenUserIds,
                selectedCorpGroupDepartmentIds,
                selectedDepartmentIds,
                selectedCorpGroupUserIds,
                type: this.type, // 1 部門 其他 員工
            })
                .then((res) => {
                    const { result, resPart } = dealResult(res)
                    let isUserNode =
                        this.confineNodeSelectByChoosePerson && this.confineNodeSelectByChoosePerson(result, this[list])
                    if (isList) {
                        isUserNode && this.$set(this[list][index], 'val', [...result, ...resPart])
                    } else {
                        isUserNode && this.$set(this, list, [...result, ...resPart])
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getIdKey(type) {
            return type === 'departmentName' ? 'departmentId' : 'userId'
        },
        // 處理通訊錄數據
        filter(ids) {
            return (
                ids &&
                JSON.stringify(
                    ids.map(({ corpId, userId, id, type, corpName }) => ({
                        [this.getIdKey(type)]: userId || id,
                        corpId,
                        type,
                        corpName: corpName || '',
                    }))
                )
            )
        },
        // 處理節點數據
        handlerNodeList() {
            const arr = []
            arr.push({
                name: '接收人',
                sort: 99,
                userList: this.mainList.map((j) => ({
                    userId: j.userId,
                    corpId: j.corpId,
                    status: 0,
                })),
            })
            arr.push({
                name: '抄送人',
                sort: 100,
                userList: this.csr_list.map((j) => ({
                    userId: j.userId,
                    corpId: j.corpId,
                    status: 0,
                })),
            })
            return arr
        },
        formatDraftId(id) {
            if (!id) return []
            return JSON.parse(id).map((j) => ({ ...j, id: j.userId || j.departmentId }))
        },
    },
}
