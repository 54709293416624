//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { downLoadAndPre } from '@/mixins/index'
import { mixinDevice } from '@/utils/mixin'
export default {
  mixins: [downLoadAndPre, mixinDevice],
  props: {
    file: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      fileType: ['.doc', '.docx', '.pdf', '.xls', 'xlsx', '.png', '.jpg'],
      spinning: false,
    }
  },
}
