//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import moment from 'moment'
export default {
  components: {
    OpenData,
  },
  data() {
    return {
      moment,
    }
  },
  props: {
    list: {
      type: Array,
      default() {
        return []
      },
    },
  },
  computed: {
    copyList() {
      return Array.isArray(this.list) ? this.list : []
    },
  },
  methods: {
    formatApprovalTime(approvalTime) {
      if (!approvalTime) return ''
      approvalTime = approvalTime.replace(/-/g, '/')
      const date = new Date(approvalTime)
      const dateStr = date.toLocaleString()
      return moment(approvalTime).format('YYYY-MM-DD HH:mm:ss')
      return dateStr.replace(/\//g, '-')
    },
  },
}
