import { axios } from '@/utils/request'
/**
 * 獲取節點面板列表
 * @param {*} parameter
 * @returns
 */
export const templateList = parameter => axios({
  url: '/flow/template/list',
  method: 'get',
  params: parameter
})
/**
 * 添加模板消息
 * @param {*} parameter
 * @returns
 */
export const addTemplate = parameter => axios({
  url: '/flow/template/add',
  method: 'post',
  data: parameter
})
/**
 * 修改模板信息
 * @param {*} parameter
 * @returns
 */
export const editTemplate = parameter => axios({
  url: '/flow/template/edit',
  method: 'post',
  data: parameter
})
/**
 * 激活模板
 * @param {*} parameter
 * @returns
 */
export const enableTemplate = parameter => axios({
  url: '/flow/template/enable',
  method: 'post',
  data: parameter
})
/**
 * 刪除模板
 * @param {*} parameter
 * @returns
 */
export const delTemplate = id => axios({
  url: `/flow/template/delete/${id}`,
  method: 'post'
})
