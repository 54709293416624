import { getFilters } from '@/views/fileStream/documentManage/config'
export const valid = [
  {
    key: 'lxdh',
    required: {
      status: false,
      msg: 'xx'
    },
    regExp: '^1[3456789]\\d{9}$',
    message: '請輸入'
  }
]
export const validation = values => {
  let msg
  valid.forEach(el => {
    const value = values[el.key]
    if (!value && el.required.status) {
      msg = el.required.msg
      return false
    }
    if (value && el.regExp) {
      const exp = new RegExp(el.regExp)
      if (!exp.test(value)) {
        msg = el.message
        return false
      }
    }
  })
  return msg
}
export const getDefaultColumns = (data, isScreening = true) => [
  {
    title: '處理箋名稱',
    dataIndex: 'name',
    ellipsis: true
  },
  {
    title: '發文號',
    dataIndex: 'documentNumber',
    ellipsis: true
  },
  // {
  //   title: '發文字號',
  //   dataIndex: 'documentNumber',
  //   ellipsis: true
  // },
  {
    title: '緊急程度',
    dataIndex: 'urgencyDegree',
    // width: 100,
    scopedSlots: { customRender: 'urgencyDegree' },
  },
  {
    title: '密級程度',
    dataIndex: 'secrecyDegree',
    // width: 100,
    scopedSlots: { customRender: 'secrecyDegree' },
  },
  {
    title: '類型',
    dataIndex: 'documentType',
    // width: 150,
    scopedSlots: { customRender: 'documentType' },
  },

  {
    title: '擬稿人',
    dataIndex: 'draftId',
    // width: 120,
    scopedSlots: { customRender: 'draftId' },
  },

  {
    title: '創建日期',
    dataIndex: 'createTime',
    // width: 150,
    scopedSlots: { customRender: 'createTime' },
  },
  {
    title: '狀態',
    dataIndex: 'approvalStatus',
    // width: 130,
    scopedSlots: { customRender: 'approvalStatus' },
    filterMultiple: false,
    filters: isScreening && getFilters(data)
  },
  {
    title: '操作',
    dataIndex: 'action',
    // fixed:'right',
    // width: 240,
    scopedSlots: { customRender: 'action' },
  }
]
