//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { FullModelView } from '@/components'
import MyForm from './formLabel.vue'
export default {
  name: 'StepsLabel',
  components: {
    FullModelView,
    MyForm
  },
  data() {
    return {
      title: '詳情',
      subTitle: '',
      visible: false,
      officialId: '',
      submitLoading: false,
      type: ''
    }
  },
  beforeDestroy() {
    this.visible = false
  },
  methods: {
    open({ type, id }) {
      this.officialId = id
      this.visible = true
      this.type = type
    },
    close() {
      this.visible = false
    },
    handleCancel() {
      this.visible = false
      if (this.$route.query.id) {
        this.$router.go(-1)
      }
    }
  }
}
