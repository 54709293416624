//
//
//
//
//
//
//
//
//
//

import process from '@/views/fileStream/documentSend/components/process.vue'
export default {
  components: {
    process,
  },
  props: {
    formMessage: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    nodesMess() {
      const nodesCopy = JSON.parse(JSON.stringify(this.nodes))
      nodesCopy.forEach((element) => {
        element.userList = element.nodes
      })
      return nodesCopy
    },
  },
}
