//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import nodes from '@/views/fileStream/documentApproval/documentManage/nodes.vue'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import fileDownAndPreView from './fileDownAndPreView.vue'
import showEditor from './showEditor.vue'
export default {
  props: {
    content: {
      type: String,
      default: '',
    },
    formObj: {
      type: Object,
      default() {
        return { name: '發文稿紙' }
      },
    },
  },
  computed: {
    list() {
      if (this.formObj.documentNodeList && this.formObj.documentNodeList.length) {
        const nodes = this.formObj.documentNodeList.slice()
        const targetArray = nodes.filter((j) => j.sort !== -1 && ![99, 100].includes(j.sort))
        return targetArray.map(({ name, nodes }) => ({ name, nodes }))
      }
      return []
    },
  },
  components: {
    OpenData,
    fileDownAndPreView,
    nodes,
    showEditor,
  },
  data() {
    return {
      dom: [],
      flow_secrecy_degree: [],
      flow_urgency_degree: [],
      ngdw_list: [],
      ngry_list: [],
      type: '',
      fileFileIdss: [],
    }
  },
  created() {
    this.getDict()
  },
  methods: {
    formatListMess(node) {
      const arr = []
      if (node.deptInfos && node.deptInfos.length > 0) {
        node.deptInfos.forEach((dep) => {
          dep.deptUsers.forEach((k) => {
            arr.push(k)
          })
        })
      }
      node.nodes = [...node.nodes, ...arr].filter((j) => j.userId && j.approvalFlag !== 2)
    },
    formatList(list) {
      const listCopy = JSON.parse(JSON.stringify(list))
      listCopy.forEach((j) => {
        this.formatListMess(j)
      })
      return listCopy.filter((j) => j.sort != -1 && ![99, 100].includes(j.sort))
    },
    // 獲取字典
    getDict() {
      sysDictTypeDropDown({ code: 'flow_secrecy_degree' }).then((res) => {
        this.flow_secrecy_degree = res.data
      })
      sysDictTypeDropDown({ code: 'flow_urgency_degree' }).then((res) => {
        this.flow_urgency_degree = res.data
      })
    },
    getDraftCompany(draftCompany) {
      if (!draftCompany) return []
      return JSON.parse(draftCompany)
    },
    // 情況表單
    clear_form() {
      this.form.resetFields()
      this.ngdw_list = []
    },
    Filter(s) {
      const values = this.flow_secrecy_degree.filter((item) => Number(item.code) === Number(s))
      if (values.length > 0) {
        return values[0].value
      }
    },
    formatSelect(key, jjcd) {
      return this[key].find((j) => Number(j.code) === Number(jjcd))?.value
    },
  },
}
