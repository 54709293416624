//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    addOfficial,
    addOfficialDraft,
    documentBillDetail,
    editOfficial,
    editOfficialDraft,
} from '@/api/modular/fileStream/documentManage'
import { templateList } from '@/api/modular/fileStream/template'
import { selectPerson } from '@/mixins'
import { EventBus } from '@/views/fileStream/documentManage/bus.js'
import { customFromValidate } from '@/views/fileStream/documentManage/minxins.js'
import templateNodes from '@/views/fileStream/documentManage/templateNodes.vue'
import { message } from 'ant-design-vue/es'
import moment from 'moment'
import { getCorpId } from '../documentManage/weixin/selectPerson'
import nodes from './components/nodes.vue'
export default {
    components: {
        templateNodes,
        nodes,
    },
    mixins: [selectPerson, customFromValidate],
    computed: {
        isShowDraft() {
            return !this.myform.draftId || this.myform.draftId === 'superAdmin'
        },
    },
    data() {
        return {
            moment,
            subLoad: false,
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 18 },
            },
            form: this.$form.createForm(this),
            myform: {},
            list: [{ name: '文宣綜合部經理審批', val: [] }],
            templateMessList: [],
            selectTemplateId: '',
            selectTemplate: null,
            csr_list: [],
            type: '',
            mainIds: [],
            sendIds: [],
            form_id: '',
            tableForm: null,
            form_state: {},
        }
    },
    props: {
        content: {
            type: String,
            default: '',
        },
        tableMess: {
            type: Object,
            default() {
                return {}
            },
        },
        getFormObj: Function,
        fileIds: {
            type: Array,
            default() {
                return []
            },
        },
        officialId: {
            type: String,
            default: '',
        },
        randomCode: 0,
    },
    created() {
        this.getTemplateList()
        this.getFormStateData()
    },
    watch: {
        randomCode: {
            immediate: true,
            handler(val) {
                this.get_info()
            },
        },
        list: {
            handler(newVal) {
                EventBus.$emit('addDom', newVal)
            },
            immediate: true,
        },
    },
    destroyed() {
        EventBus.$off('form_state', {})
    },
    methods: {
        getFormStateData() {
            EventBus.$on('form_state', (obj, form) => {
                this.form_state = obj
                this.tableForm = form
            })
        },
        getDataType({ deptId }) {
            return deptId ? 'departmentName' : 'userName'
        },
        setNodesMess(evt) {
            const { list, csrList, mainList } = evt
            this.mainIds = mainList
            this.sendIds = csrList
            this.list = list
        },
        resetList() {
            this.resetMess()
        },
        get_info() {
            this.form_id = this.officialId
            const id = this.form_id
            if (id) {
                this.$emit('setFormLoading', true)
                documentBillDetail({ id })
                    .then((res) => {
                        const {
                            acceptTime,
                            name,
                            urgencyDegree,
                            secrecyDegree,
                            deviseOpinion,
                            draftId,
                            draftCompany,
                            nodeParam,
                            // approvalWay,
                            tellPhone,
                            summary,
                            attach, //正文
                            fileIds, //附件
                            title,
                            documentNodeList,
                            documentFileList,
                            documentNumber,
                            receiverCompany,
                            mainFile,
                            templateId,
                            content,
                        } = res.data
                        const form = {
                            lwsj: acceptTime,
                            fwzh: documentNumber,
                            name,
                            jjcd: urgencyDegree ? String(urgencyDegree) : '',
                            miji: secrecyDegree ? String(secrecyDegree) : '',
                            nbyj: deviseOpinion,
                            receiverCompany,
                            draftId: this.formatDraftId(draftId),
                            draftCompany: this.formatDraftId(draftCompany),
                            // spfs: '1' || String(approvalWay),
                            wgbt: title,
                            list: nodeParam,
                            nodes: documentNodeList,
                            lxdh: tellPhone,
                            attach: mainFile && [mainFile], //正文
                            fileIds: Array.isArray(documentFileList) ? documentFileList : [], //附件
                            summary,
                            documentNumber,
                            templateId,
                            content,
                        }
                        //
                        this.echoNodes(documentNodeList)
                        this.selectTemplateId = templateId == '-1' ? '' : templateId
                        this.tableForm.setFieldsValue(form)
                        this.$emit('setDetail', form)
                    })
                    .catch(() => {
                        this.$emit('setFormLoading', false)
                        message.error('獲取內容失敗!!!')
                    })
            } else {
                this.$emit('setFormLoading', false)
                this.resetMess()
            }
        },
        resetMess() {
            this.mainIds = []
            this.sendIds = []
            this.mainList = []
            this.csr_list = []
            this.list = [{ name: '文宣綜合部經理審批', val: [] }]
            this.selectTemplateId = ''
            this.selectTemplate = null
            this.$refs.nodes && this.$refs.nodes.reset()
        },
        initNodes(tag) {
            const { nodeList } = tag
            const formatNodes = (nodes) => {
                return nodes.map((j) => ({ ...j, type: this.getDataType(j), id: j.deptId }))
            }
            this.list = nodeList
                .filter((j) => ![99, 100].includes(j.sort))
                .map((i) => ({ name: i.name, val: formatNodes(i.userList), countersign: i.countersign }))
            this.sendIds = nodeList
                .find((j) => j.sort === 100)
                .userList.map((j) => ({ ...j, type: this.getDataType(j), id: j.deptId }))
            this.mainIds = nodeList
                .find((j) => j.sort === 99)
                .userList.map((j) => ({ ...j, type: this.getDataType(j), id: j.deptId }))
        },
        // 回顯審批節點
        echoNodes(nodeList = []) {
            if (Array.isArray(nodeList)) {
                const formatNodes = (nodes) => {
                    return nodes.map((j) => ({ ...j, type: this.getDataType(j), id: j.deptId }))
                }
                this.list = nodeList
                    .filter((j) => j.sort != -1 && ![99, 100].includes(j.sort))
                    .map((i) => ({ name: i.name, val: formatNodes(i.nodes), countersign: i.countersign }))
                this.sendIds = nodeList
                    .find((j) => j.sort === 100)
                    .nodes.map((j) => ({ ...j, type: this.getDataType(j), id: j.deptId }))
                this.mainIds = nodeList
                    .find((j) => j.sort === 99)
                    .nodes.map((j) => ({ ...j, type: this.getDataType(j), id: j.deptId }))
                this.$refs.nodes.initData({ list: this.list, csrList: this.sendIds, mainList: this.mainIds })
            }
        },
        templateChange(evt) {
            this.selectTemplateId = evt
            const tem = this.templateMessList.find((j) => j.id === evt)
            if (tem) {
                this.selectTemplate = tem
                this.initNodes(tem)
            } else {
                this.selectTemplate = null
                this.list = [{ name: '文宣綜合部經理審批', val: [] }]
                this.mainIds = []
                this.sendIds = []
            }
        },
        // formatDraftId(draftId, key) {
        //   if (!draftId) return
        //   return JSON.parse(draftId)[0][key]
        // },
        formatDraftId(id) {
            if (!id) return []
            return JSON.parse(id).map((j) => ({ ...j, id: j.userId || j.departmentId }))
        },
        getTemplateList() {
            templateList({ status: 1 }).then((res) => {
                if (res.code === 200) {
                    this.templateMessList = res.data
                }
            })
        },
        getIdKey(type) {
            return type === 'departmentName' ? 'departmentId' : 'userId'
        },
        filter(ids) {
            if (!ids) return '[]'
            return JSON.stringify(
                ids.map(({ corpId, userId, id, type, corpName }) => ({
                    [this.getIdKey(type)]: userId || id,
                    corpId,
                    type,
                    corpName: corpName || '',
                }))
            )
        },
        getIds(arr) {
            if (!arr || !arr.length) return ''
            const array = []
            arr.forEach((el) => {
                const id = el.type === 'userName' ? el.userId : el.id
                array.push(id)
            })
            return array.join()
        },
        addApproval(isSave = false) {
            // const values = this.getFormObj()
            const arr = []
            if (this.list[0].val.length === 0) {
                this.$message.error('審批流程未選擇，請選擇審批人員')
                return
            } else if (this.list.some((j) => !j.val || j.val.length === 0)) {
                this.$message.error('審批流程未選擇，請選擇審批人員')
                return
            }
            if (this.subLoad) return
            this.list.forEach((el, i) => {
                const uid = []
                const departId = []
                el.val.forEach((el) => {
                    if (el.type === 'departmentName') {
                        departId.push({ departmentId: el.id, corpId: el.corpId })
                    } else if (el.type === 'userName') {
                        uid.push({ userId: el.userId, corpId: el.corpId, status: 0 })
                    }
                })
                arr.push({ name: el.name, sort: i, userList: uid, countersign: el.countersign })
            })
            arr.push({
                name: '接收人',
                sort: 99,
                userList: this.mainIds.map((j) => ({
                    userId: j.userId,
                    corpId: j.corpId,
                    status: 0,
                })),
            })
            arr.push({
                name: '抄送人',
                sort: 100,
                userList: this.sendIds.map((j) => ({
                    userId: j.userId,
                    corpId: j.corpId,
                    status: 0,
                })),
            })
            const getCorpName = (ngdwListInput) => {
                if (!ngdwListInput) return ''
                return JSON.stringify([
                    {
                        type: 'departmentName',
                        corpId: getCorpId(),
                        id: '',
                        corpName: ngdwListInput,
                    },
                ])
            }
            this.tableForm.validateFields((err, values) => {
                this.setFromValidateState({ fromValidateState: true })
                if (!err) {
                    let params = {
                        draftCompany: this.form_state.draftCompany,
                        draftId: this.form_state.draftId,
                        name: this.form_state.name,
                        ngdwListInput: this.form_state.ngdwListInput,
                        fileIds: this.form_state.fileIds,
                    }
                    this.form_state = { ...values, ...params }
                    if (!this.form_state.name) return
                    if (params.draftCompany?.length < 1 && !params.ngdwListInput) return
                    if (params.draftId?.length < 1) return
                    this.subLoad = true
                    const obj = {
                        name: this.form_state.name,
                        title: this.form_state.wgbt,
                        urgencyDegree: Number(this.form_state.jjcd) || '',
                        secrecyDegree: Number(this.form_state.miji) || '',
                        draftId: this.filter(this.form_state.draftId),
                        draftCompany: this.form_state?.ngdwListInput
                            ? getCorpName(this.form_state.ngdwListInput)
                            : this.filter(this.form_state.draftCompany),
                        fileIds: this.form_state.fileIds,
                        content: this.content,
                        documentNumber: this.form_state.fwzh,
                        nodeParam: {
                            templateId: this.selectTemplateId,
                            processesList: arr,
                        },
                        documentType: 3,
                        deviseOpinion: this.form_state.nbyj,
                        // tellPhone: this.form_state.lxdh,
                        mainIds: this.filter(this.mainIds),
                        sendIds: this.filter(this.sendIds),
                    }
                    let tip = ''
                    let id = this.form_id
                    let func = null
                    if (id) {
                        // 編輯 editOfficialDraft
                        tip = '編輯成功'
                        func = isSave ? editOfficialDraft : editOfficial
                        obj.id = id
                    } else {
                        // 新增
                        tip = '添加成功'
                        func = isSave ? addOfficialDraft : addOfficial
                    }
                    func(obj)
                        .then((res) => {
                            if (res.code === 200) {
                                this.$message.success(tip)
                                this.resetMess()
                                this.$emit('close')
                            } else {
                                this.$message.warning(res.message)
                            }
                        })
                        .finally(() => {
                            this.subLoad = false
                        })
                } else {
                    this.$message.error('表單填寫未完成，請繼續填寫')
                }
            })
        },
    },
}
