//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Ellipsis, FullModelView } from '@/components'
import { mapState } from 'vuex'
import fastFromBox from './fastFromBox.vue'
import MyForm from './form.vue'
import formBox from './fromBox.vue'
export default {
  name: 'StepsForm',
  components: {
    Ellipsis,
    FullModelView,
    MyForm,
    formBox,
    fastFromBox,
  },
  computed: {
    ...mapState({
      visible: (state) => state.bill.postMsg,
    }),
  },
  watch: {
    visible: {
      handler(val) {
        const { todo } = this.$route.query
        if (val && todo === 'draftDocument') {
          console.log("sdfsdfsdfsd====")
          this.type = 'draft'
          this.title = '擬稿發文'
          this.$router.push({})
          this.open({ type: 'draft', documentType: 3 })
        }else if(val && todo === 'draftDocumentdeeee'){
          this.title = '快速發文'
          this.$router.push({})
          this.open({ type: 'draft', documentType: 2 })
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      title: '新增',
      subTitle: '公文發送',
      // visible: false,
      officialId: '',
      submitLoading: false,
      type: '',
      randomCode: 0,
      editType: '', //修改類型
      documentType: '', // 發文類型
    }
  },
  beforeDestroy() {
    this.visible && this.$store.commit('bill/setPostMsg', false)
  },
  methods: {
    open({ type, id, documentType, editType }) {
      this.officialId = ''
      this.editType = editType
      this.type = type
      this.documentType = documentType
      console.log('_____________', this.type)
      if (type === 'edit') {
        this.officialId = id
        this.randomCode = Math.floor(Math.random() * 1024 * 1024 * 1024)
        this.title = '編輯'
        // 重審
        if (this.editType == 'retrial') {
          this.title = '修改重審'
        }
        // 續審
        if (this.editType == 'renewal') {
          this.title = '修改續審'
        }
      } else {
        this.title = '新增'
        this.officialId = ''
        this.$refs.MyForm && this.$refs.MyForm.resetDetail()
      }
      this.$store.commit('bill/setPostMsg', true)
    },
    close() {
      this.$store.commit('bill/setPostMsg', false)
      this.$emit('refresh')
    },
    handleCancel() {
      this.$store.commit('bill/setPostMsg', false)
      this.$emit('ok')
    },
    changeStatus() {},
  },
}
