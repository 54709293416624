//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'
import draftDetail from './draftDetail.vue'
import DrawerLabel from './drawerLabel.vue'
import myform from './form.vue'
import StepForm from './stepForm.vue'
import stepFormFuchsia from './stepFormFuchsia.vue'
import mytable from './table.vue'
export default {
    components: { mytable, myform, StepForm, stepFormFuchsia, DrawerLabel, draftDetail },
    data() {
        return {
            btck: 0,
            btna: ['全部', '未完成', '已完成', '草稿箱'],
        }
    },
    watch: {
        '$route.query.id': {
            handler() {
                if (this.$route.query.id) {
                    this.$nextTick(() => {
                        this.$refs.DrawerLabel.open({
                            id: this.$route.query.id,
                            type: this.$route.query.type ? this.$route.query.type : '',
                            todo: this.$route.query.todo ? this.$route.query.todo : '',
                        })
                    })
                }
            },
            immediate: true,
        },
    },
    created() {
        init_wx()
    },
    methods: {
        gwfs() {
            this.btck = 0
            this.$router.replace({ path: '/fileStreamDocumentSend' })
        },
        change_status(i) {
            this.btck = i
            const active = i === 3 ? 1 : 2
            this.$refs.mytable.query({ approvalTab: i, active, index: i })
        },
    },
}
