//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// eslint-disable-next-line camelcase
import { vali_exist } from '@/utils/validator'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import editor from '@/views/fileStream/documentManage/editor.vue'
import upload from '@/views/fileStream/documentManage/upload.vue'
import { documentBillDetail, acceptDocumentBill } from '@/api/modular/fileStream/documentManage'
import { remindPeopleApi } from '@/api/modular/fileStream/documentSend'
import userOne from '@/views/fileStream/documentManage/weixin/user_one'
import user from '@/views/fileStream/documentManage/weixin/user'
import Vue from 'vue'
export default {
  components: { editor, upload, userOne, OpenData, user },
  props: {
    officialId: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      labelCol: {
        sm: { span: 5 }
      },
      wrapperCol: {
        sm: { span: 19 }
      },
      approvalStatus: -1,
      form: this.$form.createForm(this),
      formMessage: {},
      flow_secrecy_degree: [],
      flow_urgency_degree: [],
      flow_file_type: [],
      fileIds: [],
      content: '',
      ngdw_list: [],
      hgdw_list: [],
      wbhgdw_list: [],
      checkIds: [],
      mainIds: [],
      sendIds: [],
      handler: vali_exist,
      fileName: [],
      userId: '',
      downloadIds:[], // 下載人員
      acceptIds:[], // 接受人員
      seeStatusIds:[] // 查看人員
    }
  },
  watch: {
    ngdw_list(v) {
      this.form.setFieldsValue({ ngdw_list: v.toString() })
    },
    hgdw_list(v) {
      this.form.setFieldsValue({ hgdw_list: v.toString() })
    },
    wbhgdw_list(v) {
      this.form.setFieldsValue({ wbhgdw_list: v.toString() })
    },
    checkIds(v) {
      this.form.setFieldsValue({ checkIds: v.toString() })
    },
    mainIds(v) {
      this.form.setFieldsValue({ mainIds: v.toString() })
    },
    sendIds(v) {
      this.form.setFieldsValue({ sendIds: v.toString() })
    },
    officialId() {
      this.get_info()
    }
  },
  mounted() {
    this.get_info()
    if (Vue.ls.get('WX_data_set')) {
      this.userId = Vue.ls.get('WX_data_set').userId
    }
  },
  created() {
    this.getDict()
  },
  methods: {
    formatSelectValue(array, value) {
      const resValue = array.find(j => j.code === value)?.value
      return resValue || ''
    },
    get_info() {
      this.form_id = this.officialId
      const id = this.form_id
      if (id) {
        documentBillDetail({ id }).then((res) => {
          console.log('res.data', res.data)
          // eslint-disable-next-line camelcase
          const { get_arr } = this
          const {
            documentNumber,
            outDraftDesc,
            name,
            urgencyDegree,
            secrecyDegree,
            draftId,
            fileType,
            documentFileList,
            content,
            meetId,
            outDraftId,
            approvalStatus
          } = res.data
          const form = {
            biaoti: name,
            jjcd: String(urgencyDegree),
            miji: String(secrecyDegree),
            userId: draftId,
            xzfn: String(fileType),
            fileIds: documentFileList,
            content,
            hgdw_list: meetId,
            wbhgdw_list: outDraftId,
            checkIds: res.data.checkIds,
            mainIds: res.data.mainIds,
            sendIds: res.data.sendIds,
            documentNumber,
            outDraftDesc
          }
          this.approvalStatus = approvalStatus
          //
          this.form.setFieldsValue(form)
          this.formMessage = form
          //
          this.ngdw_list = get_arr(draftId)
          this.hgdw_list = get_arr(meetId)
          this.wbhgdw_list = get_arr(outDraftId)
          this.checkIds = get_arr(res.data.checkIds)
          this.mainIds = get_arr(res.data.mainIds)
          this.sendIds = get_arr(res.data.sendIds)
          this.downloadIds = []
          this.acceptIds = []
          this.seeStatusIds = []
          res.data.acceptList.forEach((item)=>{
            if(item.acceptStatus == 1){
              this.acceptIds.push(item)
            }
            if(item.downloadStatus == 1){
              this.downloadIds.push(item)
            }
            if(item.seeStatus == 1){
              this.seeStatusIds.push(item)
            }
          })
          //
          this.fileIds = documentFileList
        })
      }
    },
    /**
     * 接收公文
     */
    acceptBill() {
      acceptDocumentBill(this.officialId)
      .then(res => {
        if (res.code === 200) {
          this.$message.success('接收成功')
          this.$router.go(-1)
        }
      })
      .catch(err => {
        console.log(err)
      })
    },
    /**
     * 獲取字典數據
     */
    getDict() {
      sysDictTypeDropDown({ code: 'flow_secrecy_degree' }).then((res) => {
        this.flow_secrecy_degree = res.data
      })
      sysDictTypeDropDown({ code: 'flow_urgency_degree' }).then((res) => {
        this.flow_urgency_degree = res.data
      })
      sysDictTypeDropDown({ code: 'flow_file_type' }).then((res) => {
        this.flow_file_type = res.data
      })
    },

    clear_form() {
      this.form.resetFields()
      this.ngdw_list = []
      this.hgdw_list = []
      this.wbhgdw_list = []
      this.checkIds = []
      this.mainIds = []
      this.sendIds = []
    },
    empty() {
      this.$refs.upload.empty()
    },
    get_arr(ids) {
      if (!ids) return []
      return JSON.parse(ids)
    },
    /**  提醒附件的各種操作  */
    async remindPeople() {
      const id = this.form_id
      try {
        const res = await remindPeopleApi({ id, name: this.formMessage.biaoti, documentNumber: this.formMessage.documentNumber })
        if (res.code === 200) {
          this.$message.success('提醒發送成功')
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
