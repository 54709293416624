//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// eslint-disable-next-line camelcase
import { delDocumentBill, documentBillDispatchPage } from '@/api/modular/fileStream/documentManage'
import { remindPeopleApi } from '@/api/modular/fileStream/documentSend'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { STable } from '@/components'
import { stateColor } from '@/mixins/index.js'
import { handleAppointEleHeight } from '@/utils/common.js'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'
import { getDefaultColumns } from './config'
export default {
    name: 'list',
    components: {
        STable,
        OpenData,
    },
    mixins: [stateColor],
    data() {
        const formatDraftId = (id) => {
            if (!id) return []
            return JSON.parse(id)
        }
        return {
            btck: 0,
            // 查詢參數
            queryParam: { documentType: 2, approvalTab: 0 },
            active: 2,
            currentActive: 0,
            // 表頭
            columns: [],
            // 加載數據方法 必須為 Promise 對象
            loadData: (parameter) => {
                const { approvalStatus } = parameter
                let params = { ...parameter }
                if (approvalStatus && approvalStatus.length > 0)
                    params = { ...parameter, approvalStatus: approvalStatus[0] }
                return documentBillDispatchPage(Object.assign(params, this.queryParam, { status: this.active }))
                    .then((res) => {
                        const data = { ...res.data }
                        data.rows = data.rows.map((ele) => {
                            ele.draftId = formatDraftId(ele.draftId)
                            ele.mainIds = formatDraftId(ele.mainIds)
                            ele.sendIds = formatDraftId(ele.sendIds)
                            return ele
                        })
                        handleAppointEleHeight('#ygh-content-box', ['.comp', '.s-table-tool'], 66, res)
                        return data
                    })
                    .catch((err) => {
                        return {
                            pageNo: 1,
                            pageSize: 10,
                            rainbow: [],
                            rows: [],
                            totalPage: 0,
                            totalRows: 0,
                        }
                    })
            },
            selectedRowKeys: [],
            selectedRows: [],
            flow_secrecy_degree: [],
            flow_urgency_degree: [],
            flow_file_type: [],
            flow_approval_status: [],
        }
    },
    created() {
        this.getDict()
        init_wx()
    },
    mounted() {
        handleAppointEleHeight('#ygh-content-box', ['.comp', '.s-table-tool'], 66)
    },
    methods: {
        /**  提醒附件的各種操作  */
        async remindPeople({ id, name, documentNumber }) {
            try {
                const res = await remindPeopleApi({ id, name, documentNumber })
                if (res.code === 200) {
                    this.$message.success('提醒發送成功')
                }
            } catch (error) {
                console.log(error)
            }
        },
        judgeIsShowDeal({ approvalStatus, recipientStatus, documentType }) {
            return [2, 4].includes(approvalStatus) && recipientStatus !== 1 && documentType === 3
        },
        deal(record) {
            this.$router.push({ path: '/fileStream_Approval_process', query: { id: record.id, todo: 'dealWith' } })
        },
        formatStatus(text) {
            switch (Number(text)) {
                case 1:
                    return '草稿'
                case 2:
                    return '已發布'
                case 3:
                    return '撤回'
                case 4:
                    return '刪除'
            }
        },
        query({ approvalTab, active, index }) {
            this.active = active
            this.currentActive = index
            this.queryParam.approvalTab = approvalTab
            this.columns = getDefaultColumns(this.flow_approval_status, approvalTab === 0)
            this.refresh()
        },
        Filter(arr, s) {
            // eslint-disable-next-line eqeqeq
            const values = Array.isArray(arr) ? arr.filter((item) => item.code == s) : []
            if (values.length > 0) {
                return values[0].value
            }
        },
        delDraft({ id }) {
            delDocumentBill([
                {
                    id,
                },
            ])
                .then((res) => {
                    if (res.code === 200) {
                        this.$message.success('刪除成功')
                        this.refresh()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        refresh() {
            this.$refs.table.refresh()
        },
        /**
         * 獲取字典數據
         */
        getDict() {
            sysDictTypeDropDown({ code: 'flow_secrecy_degree' }).then((res) => {
                this.flow_secrecy_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_urgency_degree' }).then((res) => {
                this.flow_urgency_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_file_type' }).then((res) => {
                this.flow_file_type = res.data
            })
            sysDictTypeDropDown({ code: 'flow_approval_status' }).then((res) => {
                this.flow_approval_status = res.data
                this.columns = getDefaultColumns(res.data)
            })
        },
        onSearch(value) {
            this.$refs.table.refresh(true)
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys
            this.selectedRows = selectedRows
        },
        edit(record) {
            this.$emit('showDraw', {
                type: 'edit',
                id: record.id,
                editType: record.editType,
                documentType: record.documentType,
            })
        },
    },
}
