//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import minxin from '@/views/fileStream/archived/minxins.js'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import fileDownAndPreView from '@/views/fileStream/documentSend/components/fileDownAndPreView.vue'
export default {
  props: {
    content: {
      type: String,
      default: '輸入正文',
    },
    formObj: {
      type: Object,
      default() {
        return { name: '發文稿紙' }
      },
    },
  },
  mixins: [minxin],
  components: {
    OpenData,
    fileDownAndPreView,
  },
  data() {
    return {
      ngdw_list: [],
      ngry_list: [],
    }
  },
  methods: {},
}
