//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sysFileInfoUpload } from '@/api/modular/system/fileManage'
import { AntdEditor } from '@/components'
import { retrial } from '@/views/fileStream/documentManage/minxins'
import { corpId, userId } from '@/views/fileStream/documentManage/weixin/selectPerson'
import processMess from '@/views/fileStream/documentSend/components/process.vue'
import moment from 'moment'
import editor from '../documentManage/editor.vue'
import myform from './draft_from.vue'
import mytable from './draft_table.vue'
export default {
  components: { mytable, myform, AntdEditor, editor, processMess },
  props: {
    officialId: {
      type: String,
      default: '',
    },
    todoType: {
      type: String,
      default: 'registration',
    },
    randomCode: {
      type: Number,
      default: 0,
    },
    editType: {
      type: '',
      default: '',
    },
  },
  mixins: [retrial],
  data() {
    return {
      fileIds: [],
      content: '',
      tabMess: {},
      active: 1,
      info: {},
      form: this.$form.createForm(this),
      // 文本編輯器
      editorUploadConfig: {
        method: 'http',
        callback: this.editorUploadImage,
      },
      editorContent: '',
      editorContentText: '',
      moment,

      formLoading: false,
    }
  },
  methods: {
    changeEditor(html, ele) {
      this.editorContent = html
      this.editorContentText = ele.text()
    },
    handleChange(evt) {
      this.tabMess = evt
    },
    tanslateTab() {
      this.active = 2
    },
    getEditor(editor) {
      this.editor = editor
    },
    /**
     * 編輯器回調上傳及回傳圖片url
     */
    editorUploadImage(files, insert) {
      const formData = new FormData()
      files.forEach((file) => {
        formData.append('file', file)
      })
      sysFileInfoUpload(formData).then((res) => {
        if (res.success) {
          insert(process.env.VUE_APP_API_BASE_URL + '/sysFileInfo/preview?id=' + res.data)
        } else {
          this.$message.error('編輯器上傳圖片失敗：' + res.message)
        }
      })
    },
    /**
     * YGH  添加loading
     */
    setFormLoading(e) {
      this.formLoading = e
    },
    setDetail(data) {
      this.info = data
      const { content, documentFileList } = data
      this.content = content
      this.fileIds = documentFileList
      this.formLoading = false
    },
    // 清空頁面緩存值
    resetDetail() {
      this.formLoading = false
      this.$refs.mytable && this.$refs.mytable.resetFormInfo()
      this.$refs.myform && this.$refs.myform.resetList()
      let info = {
        name: '發文稿紙',
        fileIds: [],
        attach: [],
        draftCompany: [],
        draftId: [
          {
            type: 'userName',
            corpId,
            userId,
          },
        ],
        checkIds: [],
      }
      this.content = ''
      this.fileIds = []
      this.$set(this, 'info', info)
    },
    changeStatus(evt) {
      this.$emit('changeStatus', evt)
    },
    close() {
      this.$emit('close')
      this.content = '輸入正文'
      this.$refs.mytable.reset()
    },
    //提交續審數據
    submitRenewalData() {
      const values = this.getFormObj()
      const obj = {
        id: values?.id,
        name: values.name,
        title: values.wgbt,
        urgencyDegree: Number(values.jjcd) || '',
        secrecyDegree: Number(values.miji) || '',
        draftId: this.filter(values.ngry_list),
        // fileType: Number(values.xzfn) || '',
        fileIds: values.fileIds,
        content: this.content,
        documentNumber: values.fwzh,
        draftCompany: this.getCorpName(values.ngdwListInput) || this.filter(values.ngdw_list),
        deviseOpinion: values.nbyj,
        tellPhone: values.lxdh,
      }
      this.handleSubmit(obj)
    },
  },
  destroyed() {
    this.formLoading = false
  },
}
