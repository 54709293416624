//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { documentBillDetail } from '@/api/modular/fileStream/documentManage'
import { FullModelView } from '@/components'
import billNodes from './components/billNodes.vue'
import billTable from './components/billTable.vue'
import fastNodes from './components/fastNodes.vue'
import fastTable from './components/fastTable.vue'
export default {
  name: 'StepsLabel',
  components: {
    FullModelView,
    billNodes,
    billTable,
    fastTable,
    fastNodes,
  },
  data() {
    return {
      title: '詳情',
      subTitle: '',
      visible: false,
      officialId: '',
      submitLoading: false,
      type: '',
      formMessage: {},
      todo: '',
    }
  },
  beforeDestroy() {
    this.visible = false
  },
  methods: {
    open({ type, id, todo }) {
      console.log('id++++++++', id)
      this.officialId = id
      this.visible = true
      this.type = type
      this.todo = todo
      this.getInfo(id)
    },
    getInfo(id) {
      this.form_id = this.officialId
      // const id = this.form_id
      if (id) {
        documentBillDetail({ id }).then((res) => {
          console.log('res.data', res.data)
          const {
            documentNumber,

            name,
            urgencyDegree,
            secrecyDegree,
            draftId,
            draftCompany,
            templateName,
            tellPhone,
            recipientStatus,
            fileType,
            documentFileList,
            documentNodeList,
            content,
            meetId,
            title,
            outDraftId,
            deviseOpinion,
            approvalStatus,
            mainFile,
            meetCompany,
            meetDesc,
            outDraftCompany,
            outDraftDesc,
            summary,
          } = res.data
          const form = {
            attach: mainFile && [mainFile], //正文
            meetCompany,
            meetDesc,
            outDraftCompany,
            name,
            approvalStatus,
            recipientStatus,
            jjcd: urgencyDegree,
            miji: secrecyDegree,
            userId: draftId,
            draftId,
            summary,
            mainFile,
            templateName,
            draftCompany,
            xzfn: String(fileType),
            fileIds: documentFileList,
            content,
            title,
            deviseOpinion,
            tellPhone,
            documentNodeList,
            hgdw_list: meetId,
            wbhgdw_list: outDraftId,
            checkIds: res.data.checkIds,
            mainIds: res.data.mainIds,
            sendIds: res.data.sendIds,
            documentNumber,
            outDraftDesc,
          }
          this.approvalStatus = approvalStatus
          //
          this.formMessage = form
          this.downloadIds = []
          this.acceptIds = []
          this.seeStatusIds = []
          //
          this.fileIds = documentFileList
        })
      }
    },
    close() {
      this.visible = false
    },
    handleCancel() {
      this.visible = false
      if (this.$route.query.id) {
        this.$router.go(-1)
      }
    },
  },
}
