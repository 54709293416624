//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  documentFormAddApi,
  documentFormEditApi,
  documentFormPageApi,
  toPdf,
} from '@/api/modular/fileStream/documentHead'
import { Ellipsis, FullModelView } from '@/components'
import editor from './editor'
export default {
  name: 'StepsForm',
  components: {
    Ellipsis,
    FullModelView,
    editor,
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  data() {
    return {
      title: '正文套紅',
      type: 'add',
      subTitle: '正文套紅',
      visible: false,
      billInfo: {},

      HeadList: [], // 模板列表

      content: '', // 富文本內容 （ 合成顯示 ）
      mainContent: '', // 正文 內容
      headContent: '', // 紅頭  內容
      headContentOrigin: '',
      confirmLoading: false, // 提交loading
      form: {
        id: '',
        name: '',
        content: '',
        serial: '',
        time: '',
      },
      rules: {
        name: [{ required: true, message: '請輸入名稱', trigger: ['change', 'blur'] }],
        time: [
          { required: true, message: '請輸入發文年限', trigger: ['change', 'blur'] },
          { validator: this.handleFuchsiaTime },
        ],
        serial: [{ required: true, message: '請輸入字號', trigger: ['change', 'blur'] }],
      },
    }
  },
  methods: {
    handleChangeYear() {
      this.headContent = this.headContentOrigin
        .replace('year', this.form.time || 'year')
        .replace('serial', this.form.serial || 'serial')
      this.content = this.headContent + this.mainContent
    },
    open(data) {
      this.mainContent = data.content || ''
      this.visible = true
      this.billInfo = data
      this.getDocumentHeadList()
      this.content = this.mainContent
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate()
      })
    },
    handleFuchsiaTime(rule, value, callback) {
      let year = new Date().getFullYear()
      let reg = /^[0-9]*$/gi
      if (value && !reg.test(value)) {
        callback('請輸入正確的年限')
      }
      if (value && value < '1970') {
        callback('輸入年限不能小於1970')
      }
      if (value && value > year) {
        callback('輸入年限不能大於當前年份')
      }
      callback()
    },
    /**
     * 獲取模板列表
     */
    getDocumentHeadList() {
      documentFormPageApi({
        pageNo: 1,
        pageSize: 100,
      })
        .then((res) => {
          this.HeadList = [].concat(res.data.rows)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    close() {
      this.visible = false
      this.$emit('refresh')
    },
    handleCancel() {
      this.visible = false
      this.$emit('refresh')
    },
    resetContent() {
      this.form = Object.assign(
        {},
        {
          id: '',
          name: '',
          content: '',
        }
      )
    },
    /**
     * 選擇對應的紅頭模板
     */
    selectHead(value, option) {
      console.log('value, option===', value, option)
      this.headContent = this.HeadList.find((item) => item.id === value)?.content
      this.headContentOrigin = this.headContent
      this.content = this.headContent + this.mainContent
    },
    /**
     * 提交表單校驗
     */
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          toPdf({
            billId: this.billInfo.id,
            content: this.content,
          }).then((res) => {
            if (res.code === 200) {
              this.$message.success('套紅成功')
              this.close()
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 新增
    documentFormAddFun() {
      this.confirmLoading = true
      documentFormAddApi({ ...this.form })
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message)
            this.handleCancel()
          } else {
            this.$message.error(res.message)
          }
          this.confirmLoading = false
        })
        .finally((res) => {
          this.confirmLoading = false
        })
    },
    // 編輯
    documentFormEditFun() {
      this.confirmLoading = true
      documentFormEditApi({ ...this.form })
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message)
            this.handleCancel()
          } else {
            this.$message.error(res.message)
          }
          this.confirmLoading = false
        })
        .finally((res) => {
          this.confirmLoading = false
        })
    },
  },
}
