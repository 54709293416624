import { uploadFileTransform } from '@/api/modular/fileStream/documentManage';
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage';
import { sysFileInfoDownload } from '@/api/modular/system/fileManage';
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin';
import html2Canvas from 'html2canvas'; //
import JsPDF from 'jspdf';
import moment from 'moment';
export default {
    data() {
        return {
            flow_secrecy_degree: [],
            flow_urgency_degree: [],
            type: '',
            upload_img: require('@/assets/img/upload.png'),
            isShow: true,
            spinning: false,
            script1: null,
            script2: null,
            moment,
        }
    },
    computed: {
        list() {
            if (this.formObj.documentNodeList && this.formObj.documentNodeList.length) {
                const nodes = this.formObj.documentNodeList.slice()
                return nodes.filter((j) => j.sort !== -1)
            }
            return [{ name: '文宣綜合部經理審批', val: [] }]
        },
    },
    created() {
        this.getDict()
    },
    methods: {
        // 獲取字典
        getDict() {
            sysDictTypeDropDown({ code: 'flow_secrecy_degree' }).then((res) => {
                this.flow_secrecy_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_urgency_degree' }).then((res) => {
                this.flow_urgency_degree = res.data
            })
        },
        formatSelect(key, jjcd) {
            return this[key].find((j) => Number(j.code) === Number(jjcd))?.value
        },
        getDraftCompany(draftCompany) {
            if (!draftCompany) return []
            return JSON.parse(draftCompany)
        },
        Filter(s) {
            const values = this.flow_secrecy_degree.filter((item) => Number(item.code) === Number(s))
            if (values.length > 0) {
                return values[0].value
            }
        },
        clearWX(dom) {
            return new Promise(resolve => {
                this.spinning = true
                // window.wx = null           
                if (!this.script1 && !this.script2) {
                    const script1 = document.createElement('script')
                    script1.referrerpolicy = "origin"
                    script1.src = 'https://res.wx.qq.com/open/js/jweixin-1.2.0.js'
                    script1.referrerpolicy = "origin"
                    dom.appendChild(script1)
                    this.script1 = script1
                    script1.onload = function () {
                        const script2 = document.createElement('script')
                        script2.referrerpolicy = "origin"
                        script2.src = 'https://open.work.weixin.qq.com/wwopen/js/jwxwork-1.0.0.js'
                        script2.referrerpolicy = "origin"
                        dom.appendChild(script2)
                        this.script2 = script2
                        script2.onload = function () {
                            // TODO something  
                            init_wx().then(() => {
                                resolve()
                            }).catch((err) => {
                                this.spinning = false
                            })

                        }

                    }
                } else {
                    init_wx().then(() => {
                        resolve()
                    }).catch((err) => {
                        this.spinning = false
                    })
                }

            })
        },
        // 下載當前文件
        download(record) {
            this.cardLoading = true
            sysFileInfoDownload({ id: record.fileId })
                .then((res) => {
                    this.cardLoading = false
                    this.editAcceptBill({ downloadStatus: 1 })
                    this.downloadfile(res)
                    // eslint-disable-next-line handle-callback-err
                })
                .catch(() => {
                    this.cardLoading = false
                    this.$message.error('下載錯誤：獲取文件流錯誤')
                })
        },
        //避免分頁被截斷
        outPutPdfFn(dom, title) {
            this.clearWX(dom).then(() => {
                let vm = this;
                const A4_WIDTH = 592.28;
                const A4_HEIGHT = 841.89;
                // $myLoading 自定義等待動畫組件，實現導出事件的異步等待交互
                vm.$nextTick(() => {
                    // dom的id。
                    let target = dom;
                    let pageHeight = target.scrollWidth / A4_WIDTH * A4_HEIGHT;
                    // 獲取分割dom，此處為class類名為item的dom
                    let lableListID = document.getElementsByClassName('bigtt');
                    // 進行分割操作，當dom內容已超出a4的高度，則將該dom前插入一個空dom，把他擠下去，分割
                    let newNode = null
                    for (let i = 0; i < lableListID.length; i++) {
                        let multiple = Math.ceil((lableListID[i].offsetTop + lableListID[i].offsetHeight) / pageHeight);
                        if (this.isSplit(lableListID, i, multiple * pageHeight)) {
                            let divParent = lableListID[i].parentNode; // 獲取該div的父節點
                            newNode = document.createElement('div');
                            newNode.className = 'emptyDiv';
                            // newNode.style.background = '#01195e';
                            let _H = multiple * pageHeight - (lableListID[i].offsetTop + lableListID[i].offsetHeight);
                            newNode.style.height = _H / 2 + 30 + 'px';
                            newNode.style.width = '100%';
                            let next = lableListID[i].nextSibling; // 獲取div的下一個兄弟節點
                            // 判斷兄弟節點是否存在
                            // console.log(next);
                            if (next) {
                                // 存在則將新節點插入到div的下一個兄弟節點之前，即div之後
                                divParent.insertBefore(newNode, next);
                            } else {
                                // 不存在則直接添加到最後,appendChild默認添加到divParent的最後
                                divParent.appendChild(newNode);
                            }
                        }
                    }
                    this.uploadToPdf(dom, title, newNode);
                });
            }).catch(err => {
                this.spinning = false
            })
        },
        // 判斷是否需要添加空白div
        isSplit(nodes, index, pageHeight) {
            // 計算當前這塊dom是否跨越了a4大小，以此分割
            if (nodes[index].offsetTop + nodes[index].offsetHeight < pageHeight && nodes[index + 1] && nodes[index + 1].offsetTop + nodes[index + 1].offsetHeight > pageHeight) {
                return true;
            }
            return false;
        },
        //移除添加的元素
        removeNode(node) {
            if (node) {
                node.remove()
            }
        },
        // 下載當前表單
        uploadToPdf(dom, title, newNode) {
            let _this = this
            _this.isShow = false
            setTimeout(() => {
                html2Canvas(dom, {
                    // allowTaint: true // 允許 canvas 汙染，allowTaint 參數要去掉，否則是無法通過 toDataURL 導出 canvas 數據的
                    dpi: 300,
                    scale: 4, // 設置可以解決導出模糊的問題
                    useCORS: true // 允許 canvas 畫布內 可以跨域請求外部鏈接圖片，允許跨域請求
                }).then(function (canvas) {
                    let contentWidth = canvas.width
                    let contentHeight = canvas.height
                    // 一頁pdf顯示html頁面生成的canvas高度
                    let pageHeight = contentWidth / 592.28 * 841.89
                    // 未生成pdf的html頁面高度
                    let leftHeight = contentHeight
                    // 頁面偏移
                    let position = 0
                    // a4紙的尺寸[595.28,841.89]，html頁面生成的canvas在pdf中圖片的寬高
                    let imgWidth = 595.28
                    let imgHeight = 592.28 / contentWidth * contentHeight
                    let pageData = canvas.toDataURL('image/jpeg', 1.0)
                    let PDF = new JsPDF('', 'pt', 'a4')
                    // 有兩個高度需要區分，一個是html頁面的實際高度，和生成pdf的頁面高度(841.89)
                    // 當內容未超過pdf一頁顯示的範圍，無需分頁
                    if (leftHeight < pageHeight) {
                        PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
                    } else {
                        while (leftHeight > 0) {
                            PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                            leftHeight -= pageHeight
                            position -= 841.89
                            // 避免添加空白頁
                            if (leftHeight > 0) {
                                PDF.addPage()
                            }
                        }
                    }
                    console.log('dom+++++++++', dom)
                    _this.isShow = true
                    _this.spinning = false
                    newNode && newNode.remove()
                    PDF.save(title + '.pdf')
                }).catch(err => {
                    _this.isShow = true
                    _this.spinning = false
                    this.$message.error(`下載失敗，失敗原因：${err.message}`)
                })
            });
        },
        // 下載當表單url
        uploadFileTransform() {
            const { id, name } = this.formObj
            this.spinning = true
            uploadFileTransform({ ids: id }).then(res => {
                if (res?.data) {
                    this.createNodeUploadFile(res?.data, name)
                }
            }).catch(err => {
                this.spinning = false
            })
        },
        // 創建下載a標簽下載文件
        createNodeUploadFile(url, filename) {
            var downloadElement = document.createElement('a')
            var reg = /^["](.*)["]$/g
            downloadElement.style.display = 'none'
            downloadElement.href = url
            downloadElement.download = decodeURI(filename.replace(reg, '$1')) // 下載後文件名
            document.body.appendChild(downloadElement)
            downloadElement.click() // 點擊下載
            document.body.removeChild(downloadElement) // 下載完成移除元素
            this.spinning = false
            window.URL.revokeObjectURL(href)
        },

    }
}

export const handleFile = {
    methods: {
        getFileContentTypeByFileName(fileName=''){
            if (fileName) {
                let arr = fileName.split('.')
                if(arr.length > 1){
                    return blobType[arr[1]]
                }
            }
        }
    }
}

const blobType = {
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    csv: 'text/csv',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    pdf: 'application/pdf',
    ppt: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    png: 'image/png',
    gif: 'image/gif',
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    mp3: 'audio/mpeg',
    aac: 'audio/aac',
    html: 'text/html',
    css: 'text/css',
    js: 'text/javascript',
    json: 'application/json',
    abw: 'application/x-abiword',
    arc: 'application/x-freearc',
    avi: 'video/x-msvideo',
    azw: 'application/vnd.amazon.ebook',
    bin: 'application/octet-stream',
    bmp: 'image/bmp',
    bz: 'application/x-bzip',
    bz2: 'application/x-bzip2',
    csh: 'application/x-csh',
    eot: 'application/vnd.ms-fontobject',
    epub: 'application/epub+zip',
    htm: 'text/html',
    ico: 'image/vnd.microsoft.icon',
    ics: 'text/calendar',
    jar: 'application/java-archive',
    jsonld: 'application/ld+json',
    mid: 'audio/midi audio/x-midi',
    midi: 'audio/midi audio/x-midi',
    mjs: 'text/javascript',
    mpeg: 'video/mpeg',
    mpkg: 'application/vnd.apple.installer+xml',
    odp: 'application/vnd.oasis.opendocument.presentation',
    ods: 'application/vnd.oasis.opendocument.spreadsheet',
    odt: 'application/vnd.oasis.opendocument.text',
    oga: 'audio/ogg',
    ogv: 'video/ogg',
    ogx: 'application/ogg',
    otf: 'font/otf',
    rar: 'application/x-rar-compressed',
    rtf: 'application/rtf',
    sh: 'application/x-sh',
    svg: 'image/svg+xml',
    swf: 'application/x-shockwave-flash',
    tar: 'application/x-tar',
    tif: 'image/tiff',
    tiff: 'image/tiff',
    ttf: 'font/ttf',
    txt: 'text/plain',
    vsd: 'application/vnd.visio',
    wav: 'audio/wav',
    weba: 'audio/webm',
    webm: 'video/webm',
    webp: 'image/webp',
    woff: 'font/woff',
    woff2: 'font/woff2',
    xhtml: 'application/xhtml+xml',
    xml: 'text/xml',
    xul: 'application/vnd.mozilla.xul+xml',
    zip: 'application/zip',
  }
