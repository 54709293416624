// import html2canvas from 'html2canvas'
// import JsPDF from 'jspdf'
// export const htmlToPdf = {
//     methods: {
//         // 下載圖片
//       download() {
//         this.confirmLoading = true
//         const ref = document.getElementById('files_list_item_download') //this.$refs.msgListOfList // 截圖區域
//         console.log("開始下載",ref)
//         if(!ref) {
//           this.confirmLoading = false
//           return
//         }
//         html2canvas(ref, {
//           backgroundColor: '#e8f4ff',
//           useCORS: true // 如果截圖的內容裏有圖片,可能會有跨域的情況,加上這個參數,解決文件跨域問題
//         }).then((canvas) => {
//           const dataURL = canvas.toDataURL('image/png')
//           const creatDom = document.createElement('a')
//           document.body.appendChild(creatDom)
//           creatDom.href = dataURL
//           creatDom.download = '圖片'
//           creatDom.click()
//           this.confirmLoading = false
//         }).catch(()=>{
//           this.confirmLoading = false
//         })
//       },
//       // 下載PDF
//       downloadCopy() {
//         const ref = document.getElementById('files_list_item_download') //this.$refs.msgListOfList // 截圖區域
//         console.log("開始下載",ref)
//         if(!ref) return
//         this.confirmLoading = true
//         let title = "測試當時說的都是復蘇的速度"
//         html2canvas(ref, {
//           backgroundColor: '#e8f4ff',
//           allowTaint: true
//         }).then( (canvas) => {
//           let contentWidth = canvas.width
//           let contentHeight = canvas.height
//           let pageHeight = contentWidth / 592.28 * 841.89
//           let leftHeight = contentHeight
//           let position = 0
//           let imgWidth = 595.28
//           let imgHeight = 592.28 / contentWidth * contentHeight
//           let pageData = canvas.toDataURL('image/png') //canvas.toDataURL('image/png')
//           let PDF = new JsPDF('', 'pt', 'a4')
//           if (leftHeight < pageHeight) {
//             PDF.addImage(pageData, 'PNG', 0, 0, imgWidth, imgHeight)
//           } else {
//             while (leftHeight > 0) {
//               PDF.addImage(pageData, 'PNG', 0, position, imgWidth, imgHeight)
//               leftHeight -= pageHeight
//               position -= 841.89
//               if (leftHeight > 0) {
//                 PDF.addPage();
//               }
//             }
//           }
//           PDF.save(title + '.pdf');
//           this.confirmLoading = false
//         }).catch((res)=>{
//           console.error("sdcsdfdsfsd===",res)
//           this.confirmLoading = false
//         })
//       },
//     },
// }
import { documentTabDefaultByType } from '@/api/modular/fileStream/processingSignature'
export const loadDefaultName = {
  methods: {
    documentTabDefaultByType(type, key) {
      documentTabDefaultByType(type)
      .then(res => {
        if (res.code === 200) {
          this[key].name = res.data
          this.$refs.tableTitle.getSelectOpt(res.data)
        }
      })
    }
  }
}
