//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { documentTabListByType, documentTabSetting } from '@/api/modular/fileStream/processingSignature'
export default {
    props: {
        value: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: '請輸入處理箋名稱'
        },
        addType: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            isEditTitle: false,
            isOpen: false,
            checkedNum: 1,
            selectOpt: []
        }
    },
    methods: {
        handleClickEditTitle() {
            this.isEditTitle = true
            this.isOpen = true
            this.$nextTick(() => {
                this.$refs.input && this.$refs.input.focus()
            })
        },
        setDefaultLabel(value) {
            documentTabSetting({ id: value, type: this.addType })
            .then(res => {
                if (res.code === 200) {
                    this.$message.success('設置默認名稱成功')
                    this.selectOpt.forEach(j => {
                        if (j.id === value) {
                            j.checked = true
                        } else {
                            j.checked = false
                        }
                    })
                }
            })
        },
        getSelectOpt(data) {
            documentTabListByType(this.addType)
            .then(res => {
                if (res.code === 200) {
                    this.selectOpt = res.data.map(j => ({ ...j, checked: j.name === data }))
                }
            })
        },
        handleChange({ target: { value } }) {
            this.$emit('input', value)
        },
        blurHandler() {
            this.isOpen = false
            if (this.value) this.isEditTitle = false
        },
        handleSelectChange(value, option) {
            this.$emit('input', this.selectOpt.find(j => j.id === value)?.name)
        },
        getPopupContainer() {
            return this.$refs.tableTitle
        },
        // 切換默認值
        ChangeCheckedNum({ target: { checked } }, { id }) {
            console.log('value++++++++++', checked)
            if (!checked) return
            this.setDefaultLabel(id)
        }
    }
}
