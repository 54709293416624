export const getFilters = data => data.map(element => ({
  text: element.value,
  value: element.code
}))
export const getDefaultColumns = (data, isScreening = true) => [
  {
    title: '處理箋名稱',
    dataIndex: 'name',
    ellipsis: true
  },
  // {
  //   title: '來文字號',
  //   dataIndex: 'documentNumber',
  //   ellipsis: true
  // },
  {
    title: '標題',
    dataIndex: 'title',
    ellipsis: true
  },
  {
    title: '辦文號',
    dataIndex: 'serialNumber',
    ellipsis: true
  },
  {
    title: '來文單位',
    dataIndex: 'receiverCompany',
    ellipsis: true
  },
  {
    title: '類型',
    dataIndex: 'documentType',
    // width: 150,
    scopedSlots: { customRender: 'documentType' }
  },
  {
    title: '緊急程度',
    dataIndex: 'urgencyDegree',
    // width: 100,
    scopedSlots: { customRender: 'urgencyDegree' }
  },
  {
    title: '密級程度',
    dataIndex: 'secrecyDegree',
    // width: 100,
    scopedSlots: { customRender: 'secrecyDegree' }
  },

  {
    title: '經辦人',
    dataIndex: 'draftId',
    // width: 120,
    scopedSlots: { customRender: 'draftId' }
  },

  {
    title: '創建日期',
    dataIndex: 'createTime',
    // width: 150,
    scopedSlots: { customRender: 'acceptTime' }
  },
  {
    title: '狀態',
    dataIndex: 'approvalStatus',
    // width: 120,
    scopedSlots: { customRender: 'approvalStatus' },
    filterMultiple: false,
    filters: isScreening && getFilters(data)
  },
  {
    title: '操作',
    dataIndex: 'action',
    // fixed:'right',
    // width: 240,
    scopedSlots: { customRender: 'action' }
  }
]
