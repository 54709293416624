//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { sysFileInfoUpload } from '@/api/modular/system/fileManage'
import { handleUploadCommon, selectPerson } from '@/mixins'
import nodes from '@/views/fileStream/documentApproval/documentManage/nodes.vue'
import tableTitle from '@/views/fileStream/documentManage/components/tableTitle.vue'
import { handleNodeList } from '@/views/fileStream/documentManage/minxins'
import { commonFromValidate, customFromValidate } from '@/views/fileStream/documentManage/minxins.js'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import selectPersonAndDepart, {
    corpId,
    dealResult,
    deptId,
    getUserListMsg,
    userId,
} from '@/views/fileStream/documentManage/weixin/selectPerson'
import selectPersonDialog from '@/views/fileStream/documentManage/weixin/selectPersonDialog'
import showEditor from '@/views/fileStream/documentSend/components/showEditor.vue'
import moment from 'moment'
import { EventBus } from '../documentManage/bus.js'
import { loadDefaultName } from './mixins'
export default {
    props: {
        content: {
            type: String,
            default: '',
        },
        //收文信息
        info: {
            type: Object,
            default() {
                return {}
            },
        },
        editType: {
            type: String,
            default: '',
        },
    },
    components: {
        OpenData,
        selectPersonDialog,
        tableTitle,
        showEditor,
        nodes,
    },
    mixins: [loadDefaultName, selectPerson, handleNodeList, commonFromValidate, customFromValidate, handleUploadCommon],
    data() {
        return {
            loading: false,
            moment,
            dom: [],
            form: this.$form.createForm(this, {
                onValuesChange: this.onValuesChange,
            }),
            flow_secrecy_degree: [],
            flow_urgency_degree: [],
            list: [{ name: '領導審批', val: [] }],
            ngdw_list: [],
            ngry_list: [],
            formObj: {
                name: '發文稿紙',
                ngdw_list: [],
                ngdwListInput: '',
                ngry_list: [
                    {
                        type: 'userName',
                        corpId,
                        userId,
                    },
                ],
            },
            type: '',
            fileFileIdss: [],
        }
    },
    computed: {
        isFieldValidating() {
            return false
        },
    },
    watch: {
        formObj: {
            handler(val) {
                EventBus.$emit('form_state', val, this.form)
            },
            immediate: true,
        },
        // 擬稿單位
        ngdw_list: {
            handler(val) {
                this.updateValidateState('draftCompany', !(val.length > 0))
                this.$set(this.formObj, 'draftCompany', val)
            },
            deep: true,
            immediate: true,
        },
        'formObj.ngdwListInput': {
            handler(val) {
                this.updateValidateState('draftCompany', !val)
            },
        },
        // 擬稿人
        ngry_list: {
            handler(val) {
                this.updateValidateState('draftId', !(val.length > 0))
                this.$set(this.formObj, 'draftId', val)
            },
            deep: true,
            immediate: true,
        },
        info: {
            handler(val) {
                const { fileIds, draftId, draftCompany } = val
                if (fileIds) {
                    this.fileFileIdss = Array.isArray(fileIds) ? fileIds : []
                }
                if (draftId) {
                    this.ngry_list = Array.isArray(draftId) ? draftId : []
                }
                if (draftCompany) {
                    this.ngdw_list = Array.isArray(draftCompany) ? draftCompany : []
                }
                this.formObj = val
            },
            immediate: true,
            // deep: true,
        },
    },
    destroyed() {
        EventBus.$off('addDom', {})
    },
    created() {
        this.getDict()
        this.getList()
        this.getFromValidateState()
        this.documentTabDefaultByType(3, 'formObj')
    },
    methods: {
        getFormObj() {
            const {
                form: { getFieldsValue },
            } = this
            let params = getFieldsValue()
            return { ...this.formObj, ...params, ngry_list: this.ngry_list, ngdw_list: this.ngdw_list }
        },
        reset() {
            this.formObj = {
                name: '發文稿紙',
                ngdw_list: [
                    {
                        type: 'departmentName',
                        corpId,
                        id: deptId,
                    },
                ],
                ngry_list: [
                    {
                        type: 'userName',
                        corpId,
                        userId,
                    },
                ],
            }
            this.fileFileIdss = []
            this.ngdw_list = [
                {
                    type: 'departmentName',
                    corpId,
                    id: deptId,
                },
            ]
            this.ngry_list = [
                {
                    type: 'userName',
                    corpId,
                    userId,
                },
            ]
        },
        /**
         * 上傳文件
         */
        customRequest(data) {
            const size = data.file.size / 1024 / 1024 > 100
            // const fileName = data.file.name
            // const fileType = ['.doc', '.docx', '.pdf', '.xls', 'xlsx', '.png', '.jpg'] // .doc,.docx,image/*,.pdf,.xls,.xlsx
            let isRepeat = this.handleRepeatForFileName(data.file, this.formObj.fileIds)
            if (isRepeat) {
                return this.$message.error('上傳失敗：上傳文件相同')
            }
            if (size) {
                this.$message.error('上傳失敗：文件大於100M')
                return
            }
            if (this.loading) return
            this.loading = true
            const formData = new FormData()
            formData.append('file', data.file)
            sysFileInfoUpload(formData)
                .then((res) => {
                    if (res.success) {
                        this.$message.success('上傳成功')
                        const obj = {
                            fileName: data.file.name,
                            fileId: res.data,
                            fileType: '1',
                        }
                        this.fileFileIdss = [...this.fileFileIdss, obj]
                        this.formObj.fileIds = this.fileFileIdss
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        chooseDepart(list, index) {
            this.type = 1
            this.selectPersonAndPart(list, index, 'single')
        },
        selectPersonAndPart(list, index, mode = 'multi') {
            const {
                selectedOpenUserIds,
                selectedCorpGroupUserIds,
                selectedDepartmentIds,
                selectedCorpGroupDepartmentIds,
            } = getUserListMsg(list, index, this[list])
            selectPersonAndDepart({
                selectedOpenUserIds,
                selectedCorpGroupDepartmentIds,
                selectedDepartmentIds,
                selectedCorpGroupUserIds,
                mode,
                type: this.type, // 1 部門 其他 員工
            })
                .then((res) => {
                    const { result, resPart } = dealResult(res)
                    this.$set(this, list, [...result, ...resPart])
                    this.formObj[list] = [...result, ...resPart]
                    if (list === 'ngdw_list') this.formObj.ngdwListInput = ''
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        // 通訊錄彈窗 // type 其他（員工）/1部門
        showSelctDialog(key, type, index) {
            type && (this.type = type)
            this.$refs.selectPersonDialog.open({ key, index })
        },
        objectEquail(obj1, obj2) {
            return JSON.stringify(obj1) === JSON.stringify(obj2)
        },
        choosePerson(list, index) {
            this.selectPersonAndPart(list, index)
        },
        selectTodoMsg({ type, data: { key, index, result } }) {
            if (type === 'book') {
                this.choosePerson(key, index)
            } else if (type === 'label') {
                if (key === 'list') {
                    result.forEach((ele) => {
                        if (!this[key][index].val.some((j) => this.objectEquail(j, ele))) {
                            this[key][index].val.push(ele)
                        }
                    })
                } else {
                    result.forEach((element) => {
                        if (!this[key].some((j) => this.objectEquail(j, element))) {
                            this[key].push(element)
                        }
                    })
                }
                this.formObj[key] = this[key]
            }
        },

        // 獲取字典
        getDict() {
            sysDictTypeDropDown({ code: 'flow_secrecy_degree' }).then((res) => {
                this.flow_secrecy_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_urgency_degree' }).then((res) => {
                this.flow_urgency_degree = res.data
            })
        },
        // 情況表單
        clear_form() {
            this.form.resetFields()
            // this.ngdw_list = []
        },
        Filter(s) {
            const values = this.flow_secrecy_degree.filter((item) => item.code == s)
            if (values.length > 0) {
                return values[0].value
            }
        },
        formatJjcd(jjcd) {
            return this.flow_urgency_degree.find((j) => j.code === jjcd)?.value
        },
        tanslateTab() {
            this.$emit('tanslateTab')
        },

        onChange(date, dateString) {
            console.log(date, dateString)
        },
        getList() {
            EventBus.$on('addDom', (msg) => {
                this.list = msg
            })
        },
    },
}
