//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { sysFileInfoUpload } from '@/api/modular/system/fileManage'
import { InputModal } from '@/components'
import { handleUploadCommon, selectPerson } from '@/mixins'
import { EventBus } from '@/views/fileStream/documentManage/bus.js'
import tableTitle from '@/views/fileStream/documentManage/components/tableTitle.vue'
import { commonFromValidate, customFromValidate } from '@/views/fileStream/documentManage/minxins.js'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import selectPersonAndDepart, {
    dealResult,
    getUserListMsg,
} from '@/views/fileStream/documentManage/weixin/selectPerson'
import selectPersonDialog from '@/views/fileStream/documentManage/weixin/selectPersonDialog'
import { loadDefaultName } from './mixins'

import moment from 'moment'

export default {
    props: {
        todoType: {
            type: String,
            default: 'registration',
        },
        //收文信息
        info: {
            type: Object,
            default() {
                return {}
            },
        },
        // 不可編輯
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [loadDefaultName, selectPerson, commonFromValidate, customFromValidate, handleUploadCommon],
    components: {
        OpenData,
        InputModal,
        selectPersonDialog,
        tableTitle,
    },
    data() {
        return {
            form: this.$form.createForm(this),
            formState: {
                name: '發文稿紙',
                fileIds: [],
                attach: [],
                ngdwListInput: '',
            },
            moment,
            dom: [],
            flow_secrecy_degree: [],
            flow_urgency_degree: [],
            flow_file_type: [],
            list: [],
            fileList: [],
            fileAttachs: [],
            fileFileIdss: [],
            file_type: 1,
            upload_file_data: {},

            // 通訊錄數據
            ngdw_list: [],
            ngdwListInput: '',
            ngr_list: [],
            hgr_list: [],
            loading: false,
            loading1: false,
            isPreview: false,
        }
    },
    computed: {},
    destroyed() {
        EventBus.$off('addDom', {})
    },
    created() {
        this.getDict()
        this.getList()
        this.getFromValidateState()
        this.documentTabDefaultByType(2, 'formState')
    },
    watch: {
        // 擬稿單位
        ngdw_list: {
            handler(val) {
                this.updateValidateState('draftCompany', !(val.length > 0))
                this.$set(this.formState, 'draftCompany', val)
            },
            deep: true,
            immediate: true,
        },
        // 擬稿人
        ngr_list: {
            handler(val) {
                this.updateValidateState('draftId', !(val.length > 0))
                this.$set(this.formState, 'draftId', val)
            },
            deep: true,
            immediate: true,
        },
        // 核稿人
        hgr_list: {
            handler(val) {
                this.updateValidateState('checkIds', !(val.length > 0))
                this.$set(this.formState, 'checkIds', val)
            },
            deep: true,
            immediate: true,
        },
        formState: {
            handler(val) {
                EventBus.$emit('form_state', val, this.form)
            },
            immediate: true,
        },
        'formState.ngdwListInput': {
            handler(val) {
                this.updateValidateState('draftCompany', !val)
            },
        },
        info: {
            handler(val) {
                const { draftId, checkIds, draftCompany } = val
                if (draftId) {
                    this.ngr_list = Array.isArray(draftId) ? draftId : []
                }
                if (checkIds) {
                    this.hgr_list = Array.isArray(checkIds) ? checkIds : []
                }
                if (draftCompany) {
                    this.ngdw_list = Array.isArray(draftCompany) ? draftCompany : []
                }
                this.formState = val
            },
            immediate: true,
            // deep: true,
        },
    },
    methods: {
        getFormObj() {
            const {
                form: { getFieldsValue },
            } = this
            let params = getFieldsValue()
            return {
                ...this.formState,
                ...params,
                ngr_list: this.ngr_list,
                hgr_list: this.hgr_list,
                ngdw_list: this.ngdw_list,
            }
        },
        // 處理日期
        disabledDateHandler(current) {
            return current < moment().subtract(1, 'days').endOf('day')
        },
        // 彈窗返回數據
        getvalue(data) {
            console.log('data++++++++++', data)
        },
        uploadFile(file_type) {
            this.file_type = +file_type
        },
        /**
         * 上傳正文文件
         */
        customRequestAttach(data) {
            const size = data.file.size / 1024 / 1024 > 100
            const fileName = data.file.name
            const fileType = ['.doc', '.docx', '.pdf', '.xls', 'xlsx', '.png', '.jpg'] // .doc,.docx,image/*,.pdf,.xls,.xlsx
            let isRepeat = this.handleRepeatForFileName(data.file, this.formState.attach)
            let isRepeat1 = this.handleRepeatForFileName(data.file, this.formState.fileIds)
            if (isRepeat || isRepeat1) {
                return this.$message.error('上傳失敗：上傳文件相同')
            }
            if (size) {
                this.$message.error('上傳失敗：文件大於100M')
                return
            }
            if (this.loading) return
            this.loading = true
            const formData = new FormData()
            formData.append('file', data.file)
            sysFileInfoUpload(formData)
                .then((res) => {
                    if (res.success) {
                        this.$message.success('上傳成功')
                        this.$set(this.formState, 'attach', [
                            { fileName: data.file.name, fileId: res.data, fileType: 2 },
                        ])
                        // this.$emit('update:fileIds', this.fileName)
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        /**
         * 上傳附件文件
         */
        customRequestFileIds(data) {
            const size = data.file.size / 1024 / 1024 > 100
            const fileName = data.file.name
            const fileType = ['.doc', '.docx', '.pdf', '.xls', 'xlsx', '.png', '.jpg'] // .doc,.docx,image/*,.pdf,.xls,.xlsx
            let isRepeat = this.handleRepeatForFileName(data.file, this.formState.fileIds)
            let isRepeat1 = this.handleRepeatForFileName(data.file, this.formState.attach)
            if (isRepeat || isRepeat1) {
                return this.$message.error('上傳失敗：上傳文件相同')
            }
            if (size) {
                this.$message.error('上傳失敗：文件大於100M')
                return
            }
            if (this.loading1) return
            this.loading1 = true
            const formData = new FormData()
            formData.append('file', data.file)
            sysFileInfoUpload(formData)
                .then((res) => {
                    if (res.success) {
                        this.$message.success('上傳成功')
                        this.$set(this.formState, 'fileIds', [
                            ...this.formState.fileIds,
                            { fileName: data.file.name, fileId: res.data, fileType: 1 },
                        ])
                    }
                })
                .finally(() => {
                    this.loading1 = false
                })
        },
        getDict() {
            sysDictTypeDropDown({ code: 'flow_secrecy_degree' }).then((res) => {
                this.flow_secrecy_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_urgency_degree' }).then((res) => {
                this.flow_urgency_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_file_type' }).then((res) => {
                this.flow_file_type = res.data
            })
        },
        Filter(s) {
            const values = this.flow_secrecy_degree.filter((item) => item.code == s)
            if (values.length > 0) {
                return values[0].value
            }
        },
        formatJjcd(jjcd) {
            return this.flow_urgency_degree.find((j) => j.code === jjcd)?.value
        },
        formatXzfn(xzfn) {
            return this.flow_file_type.find((j) => j.code === xzfn)?.value
        },
        onChange(date, dateString) {
            console.log(date, dateString)
        },
        getList() {
            EventBus.$on('addDom', (msg) => {
                this.list = msg
            })
        },
        chooseDepart(list, index) {
            this.type = 1
            this.selectPersonAndPart(list, index, 'single')
        },
        selectPersonAndPart(list, index, mode = 'multi') {
            const {
                selectedOpenUserIds,
                selectedCorpGroupUserIds,
                selectedDepartmentIds,
                selectedCorpGroupDepartmentIds,
            } = getUserListMsg(list, index, this[list])
            selectPersonAndDepart({
                selectedOpenUserIds,
                selectedCorpGroupDepartmentIds,
                selectedDepartmentIds,
                selectedCorpGroupUserIds,
                mode,
                type: this.type, // 1 部門 其他 員工
            })
                .then((res) => {
                    const { result, resPart } = dealResult(res)
                    this.$set(this, list, [...result, ...resPart])
                    this.formObj[list] = [...result, ...resPart]
                    if (list === 'ngdw_list') this.formState.ngdwListInput = ''
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        // 預覽當前表格文件
        preview() {
            this.isPreview = !this.isPreview
            setTimeout(() => {
                let tableToPrint = document.getElementById('table') //將要被打印的表格
                let newWin = window.open('') //新打開一個空窗口
                newWin.document.write(tableToPrint.outerHTML) //將表格添加進新的窗口
                newWin.document.close() //在IE瀏覽器中使用必須添加這一句
                newWin.focus() //在IE瀏覽器中使用必須添加這一句
                newWin.print() //打印
                newWin.close() //關閉窗口
            })
        },
    },
}
