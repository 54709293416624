import { documentBillDetail } from '@/api/modular/fileStream/documentManage'
import { editOfficialAudit } from '@/api/modular/fileStream/documentSend'
import { EventBus } from '@/views/fileStream/documentManage/bus.js'
import { getCorpId } from '../documentManage/weixin/selectPerson'
export const retrial = {
    data() {
        return {
            documentNodeList: [],
            recipientStatus: 0,
            approvalStatus: 0,
            templateName: '',
            documentType: 0,
            nooseStatus: 0,
            from: '',
            nodes: [], // 審批節點
        }
    },
    watch: {
        editType: {
            handler(val) {
                if (val) {
                    this.get_info(this.officialId)
                } else {
                    this.resetDetail()
                }
            },
            immediate: true,
        },
        nodes: {
            handler(newVal) {
                EventBus.$emit('addDom', newVal)
            },
            immediate: true,
        },
    },
    methods: {
        formatDraftId(id) {
            if (!id) return []

            return JSON.parse(id).map((j) => ({ ...j, id: j.userId || j.departmentId }))
        },
        get_info(id) {
            if (id) {
                documentBillDetail({ id }).then((res) => {
                    if (res.code === 200 && res.data) {
                        const { data } = res
                        if (!data) return
                        this.info = this.handlerTableData(data?.documentType, data)
                        const {
                            content,
                            documentFileList,
                            documentNodeList,
                            recipientStatus,
                            approvalStatus,
                            nooseStatus,
                            documentType,
                            templateName,
                        } = data
                        this.content = content
                        this.fileIds = documentFileList
                        this.documentNodeList = documentNodeList
                        this.approvalStatus = approvalStatus
                        this.recipientStatus = recipientStatus
                        this.templateName = templateName
                        this.documentType = documentType
                        this.nooseStatus = nooseStatus
                        this.nodes = documentNodeList
                            .filter((j) => ![99, 100, -1].includes(j.sort))
                            .map((i) => ({ name: i.name, val: this.formatNodes(i.userList), countersign: i.countersign }))
                    }
                })
            }
        },
        formatNodes(nodes = []) {
            return nodes.map((j) => ({ ...j, type: this.getDataType(j), id: j.deptId }))
        },
        getIdKey(type) {
            return type === 'departmentName' ? 'departmentId' : 'userId'
        },
        // 處理通訊錄數據
        filter(ids) {
            return (
                ids &&
                JSON.stringify(
                    ids.map(({ corpId, userId, id, type }) => ({
                        [this.getIdKey(type)]: userId || id,
                        corpId,
                        type,
                    }))
                )
            )
        },
        // 處理表單數據
        handlerTableData(documentType, data = {}) {
            if (!data) return
            // 收文登記
            if (+documentType == 1) {
                return {
                    ...data,
                    jjcd: `${data.urgencyDegree}`,
                    miji: `${data.secrecyDegree}`,
                    nbyj: data?.deviseOpinion,
                    lwsj: data?.acceptTime,
                    draftId: this.formatDraftId(data?.draftId),
                    wjbt: data?.title,
                    list: data?.nodeParam,
                    nodes: data?.documentNodeList,
                    fileIds: data?.documentFileList,
                    attach: data?.mainFile && [data?.mainFile], //正文
                    fileIds: data?.documentFileList, //附件
                }
            }
            // 快速發文
            if (+documentType == 2) {
                return {
                    ...data,
                    jjcd: `${data.urgencyDegree}`,
                    miji: `${data.secrecyDegree}`,
                    nbyj: data?.deviseOpinion,
                    draftId: this.formatDraftId(data?.draftId),
                    checkIds: this.formatDraftId(data?.checkIds),
                    draftCompany: this.formatDraftId(data?.draftCompany),
                    wjbt: data?.title,
                    list: data?.nodeParam,
                    attach: data?.mainFile && [data?.mainFile], //正文
                    fileIds: data?.documentFileList, //附件
                }
            }
            // 擬稿發文
            if (+documentType == 3) {
                return {
                    ...data,
                    lwsj: data?.acceptTime,
                    fwzh: data?.documentNumber,
                    jjcd: `${data.urgencyDegree}`,
                    miji: `${data.secrecyDegree}`,
                    nbyj: data?.deviseOpinion,
                    draftId: this.formatDraftId(data?.draftId),
                    draftCompany: this.formatDraftId(data?.draftCompany),
                    // wjbt: data?.title,
                    wgbt: data?.title,
                    // lxdh: data?.tellPhone,
                    list: data?.nodeParam,
                    attach: data?.mainFile && [data?.mainFile], //正文
                    fileIds: data?.documentFileList, //附件
                }

            }
            return {}
        },
        // 提交數據
        handleSubmit(data = {}) {
            if (data) {
                editOfficialAudit(data).then(res => {
                    if (res.code === 200) {
                        this.$message.success('修改成功')
                        this.$emit('close')
                    } else {
                        this.$message.warning(res.message)
                    }
                })
            }
        },
        getFormObj() {
            return this.$refs?.mytable.getFormObj()
        },
        getCorpName(ngdwListInput) {
            if (!ngdwListInput) return ''
            return JSON.stringify([
                {
                    type: 'departmentName',
                    corpId: getCorpId(),
                    id: '',
                    corpName: ngdwListInput,
                },
            ])
        }
    }
}

export const handleNodeList = {
    methods: {
        formatListMess(node) {
            const arr = []
            if (node?.deptInfos && node?.deptInfos.length > 0) {
                node.deptInfos.forEach((dep) => {
                    dep.deptUsers.forEach((k) => {
                        arr.push(k)
                    })
                })
            }
            node.nodes = [...node.nodes, ...arr].filter((j) => j.userId && j.approvalFlag !== 2)
        },
        formatList(list) {
            if (list) {
                const listCopy = JSON.parse(JSON.stringify(list))
                listCopy.forEach((j) => {
                    this.formatListMess(j)
                })
                return listCopy.filter((j) => j.sort != -1 && ![99, 100].includes(j.sort))
            }
            return []
        },
    }
}

// 表單驗證
export const commonFromValidate = {
    data() {
        return {
            options: {
                onValuesChange: this.onValuesChange
            },
            // 驗證規則列表
            rules: [
                // {
                //     name: '來文日期',
                //     key: 'acceptTime'
                // },                 
            ],
            // 驗證規則表
            validateRules: {
                // 聯系電話
                // 'tellPhone': [
                //     {
                //         required: true,
                //         message: '聯系電話不能為空',
                //     },
                //     {
                //         pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
                //         message: '請輸入正確的手機號碼',
                //     },
                // ],
                'acceptTime': [
                    { required: true, message: '來文日期不能為空' }
                ],
                'secrecyDegree': [
                    { required: true, message: '請選擇密級程度' }
                ],
                'urgencyDegree': [
                    { required: true, message: '請選擇緊急程度' }
                ],
                'deviseOpinion': [
                    { required: true, message: '文宣綜合部意見不能為空' }
                ],
                'receiverCompany': [
                    { required: true, message: '來文單位不能為空' }
                ],
                'documentNumber': [
                    { required: true, message: '來文字號不能為空' }
                ],
                'draftId': [
                    { required: true, message: '請選擇經辦人' }
                ],
                'title': [
                    { required: true, message: '文件標題不能為空' }
                ],
                'summary': [
                    { required: true, message: '文件摘要不能為空' }
                ],
                'content': [
                    { required: true, message: '正文不能為空' }
                ],
            },
            fastValidateRules: {
                'draftId': [
                    { required: true, message: '請選擇擬稿人' }
                ],
                'draftCompany': [
                    { required: true, message: '請選擇擬稿單位或輸入擬稿單位' }
                ],
                'checkIds': [
                    { required: true, message: '請選擇核稿人' }
                ],
                'documentNumber': [
                    { required: true, message: '發文字號不能為空' }
                ],
            }
        }
    },
    methods: {
        onValuesChange(props, values) {
            const { form: { validateFields, getFieldsValue } } = props
            validateFields([Object.keys(values).join()])
        },
        // 驗證數據
        validateInfo(info = {}) {
            let infoType = this.judgeDataType(info)
            if (infoType != 'Object') return
            let ruleInfo = this.rules.find((item) => !info[item.key])
            if (ruleInfo) {
                this.$message.error(`${ruleInfo.name}數據不能為空!`)
                return false
            }
            return true
        },
        judgeDataType(data = {}) {
            return Object.prototype.toString.call(data).slice(8, -1)
        },
        // 清空表單值
        resetFormInfo() {
            const { form: { resetFields } } = this
            this.resetFromList()
            resetFields()
        },
        // 清空值
        resetFromList() {
            this.hgr_list = []
            this.ngdw_list = []
            this.ngry_list = []
            this.ngdwListInput = ''
        },
        // 設置控件值
        getFormValue(value) {
            if (value) {
                return Array.isArray(value) ? value.length > 0 : true
            }
            return false
        },
    }
}

// 自定義表單驗證
export const customFromValidate = {
    data() {
        return {
            fromValidateState: false, //表單的驗證狀態
            draftCompanyValidateState: false,// 擬稿單位驗證狀態
            draftIdValidateState: false,// 擬稿人驗證狀態
            checkIdsValidateState: false,// 核稿人驗證狀態
            firstChangeState: false,// 首次更新狀態
        }
    },
    computed: {
        // fromValidateState() {
        //     const { getFieldsError } = this.form
        //     let errorObj = getFieldsError()
        //     return Object.keys(errorObj).length == 0
        // }
    },
    destroyed() {
        EventBus.$off('setFromValidateState', {})
    },
    methods: {
        // 獲取表單的驗證狀態
        getFromValidateState() {
            EventBus.$on('setFromValidateState', ({ fromValidateState }) => {
                this.fromValidateState = fromValidateState
            })
        },
        // 設置表單的驗證狀態
        setFromValidateState(fromValidateState = {}) {
            EventBus.$emit('setFromValidateState', fromValidateState)
        },
        // 更新待驗證值狀態
        updateValidateState(name, state = false) {
            if (name && this.firstChangeState) {             
                this[`${name}ValidateState`] = state
            }
        },
        // 更新首次更新狀態
        updateFirstChangeState() {
            if (this.firstChangeState) return
            this.firstChangeState = !this.firstChangeState
        }
    }
}

// 