//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { retrial } from '@/views/fileStream/documentManage/minxins'
import { corpId, userId } from '@/views/fileStream/documentManage/weixin/selectPerson'
import processMess from '@/views/fileStream/documentSend/components/process.vue'
import moment from 'moment'
import mytable from './fastTable.vue'
import myform from './form.vue'
export default {
  components: { mytable, myform, processMess },
  props: {
    officialId: {
      type: String,
      default: '',
    },
    todoType: {
      type: String,
      default: 'registration',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    randomCode: {
      type: Number,
      default: 0,
    },
    editType: {
      type: '',
      default: '',
    },
  },
  mixins: [retrial],
  data() {
    return {
      fileIds: [],
      content: '',
      info: {},
      moment,
      setFormLoading: false,
    }
  },
  methods: {
    setDetail(data) {
      this.info = data
      const { content, documentFileList } = data
      this.content = content
      this.fileIds = documentFileList
      this.formLoading = false
    },
    // 清空頁面緩存值
    resetDetail() {
      this.formLoading = false
      this.$refs.mytable && this.$refs.mytable.resetFormInfo()
      this.$refs.myform && this.$refs.myform.resetList()
      let info = {
        name: '發文稿紙',
        fileIds: [],
        attach: [],
        draftCompany: [],
        draftId: [
          {
            type: 'userName',
            corpId,
            userId,
          },
        ],
        checkIds: [],
      }
      this.content = ''
      this.fileIds = []
      this.$set(this, 'info', info)
    },
    changeStatus(evt) {
      this.$emit('changeStatus', evt)
    },
    close() {
      this.$emit('close')
    },
    //提交續審數據
    submitRenewalData() {
      const values = this.getFormObj()
      const obj = {
        id: values?.id,
        name: values?.name,
        urgencyDegree: Number(values?.jjcd),
        secrecyDegree: Number(values?.miji),
        documentNumber: values?.documentNumber,
        outDraftDesc: values?.outDraftDesc,
        outDraftCompany: values?.outDraftCompany,
        meetCompany: values?.meetCompany,
        meetDesc: values?.meetDesc,
        title: values?.wjbt,
        documentType: 2,
        summary: values?.summary,
        deviseOpinion: values?.nbyj,
        draftId: filter(values?.draftId),
        checkIds: filter(values?.checkIds),
        draftCompany: this.getCorpName(values?.ngdwListInput) || filter(values?.draftCompany),
        // tellPhone: values?.tellPhone,
        attach: values?.attach && values?.attach[0], // 正文
        fileIds: values?.fileIds, // 附件
        status: 2,
      }
      this.handleSubmit(obj)
    },
  },
}
