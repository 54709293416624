//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { acceptDocumentBill } from '@/api/modular/fileStream/documentManage'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import { userId } from '@/views/fileStream/documentManage/weixin/selectPerson'
export default {
  components: {
    OpenData,
  },
  data() {
    return {
      nodes: [
        {
          name: '接收人',
          sort: 0,
        },
        {
          name: '抄送人',
          sort: 1,
        },
      ],
    }
  },
  props: {
    formMessage: {
      type: Object,
      default() {
        return {}
      },
    },
    todoType: {
      type: String,
      default: '',
    },
  },
  computed: {
    nodesMess() {
      const { mainIds, sendIds } = this.formMessage
      if (sendIds) {
        this.stepIndex = 1
      }
      let arr = [this.formatDraftId(mainIds), this.formatDraftId(sendIds)]
      const nodeCopy = this.nodes.map((node, index) => {
        return {
          ...node,
          nodes: arr[index],
        }
      })
      return nodeCopy
    },
    documentNodeListCopy() {
      const { documentNodeList } = this.formMessage
      return documentNodeList ? documentNodeList.slice() : []
    },
    approvalFlag() {
      return this.documentNodeListCopy
        .filter((item) => item.sort == 99)[0]
        ?.nodes.filter((item) => item?.userId == userId)[0]?.approvalFlag
    },
    stepIndex() {
      return this.documentNodeListCopy.findIndex((node) => node.nodeFlag === 0) || 0
    },
  },
  methods: {
    /**
     * 接收公文
     */
    acceptBill() {
      acceptDocumentBill(this.$route.query.id)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success('接收成功')
            this.$nextTick(() => {
              this.$router.go(-1)
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    formatDraftId(id) {
      if (!id) return []
      return JSON.parse(id).map((j) => ({ ...j, id: j.userId || j.departmentId }))
    },
    getDataType(node) {
      return node.deptId ? 'departmentName' : 'userName'
    },
    formatApprovalTime(approvalTime) {
      if (!approvalTime) return ''
      const date = new Date(approvalTime)
      const dateStr = date.toLocaleString()
      return dateStr.replace(/\//g, '-')
    },
    formatApprovalStatus(approvalFlag, sort, status) {
      if (sort === 99) {
        return approvalFlag ? '已接收' : '待接收'
      }
      if (sort === 100) {
        return approvalFlag ? '已通知' : '待通知'
      }
      if (approvalFlag === 0) {
        return '待審核'
      } else if (approvalFlag === 3) {
        return '同級已審'
      } else if (status === 1) {
        return '審核通過'
      } else {
        return '駁回'
      }
    },
  },
}
