//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OpenData from '@/views/fileStream/documentManage/weixin/openData'
export default {
  components: {
    OpenData
  },
  props: {
    nodes: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    getDataType({ deptId }) {
      return deptId ? 'departmentName' : 'userName'
    }
  }
}
